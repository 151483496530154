import React, { Component } from "react";
import { Button, Form } from "react-bootstrap";
import { Steps } from "./Register";
import { flureeFetch, getToken } from "../flureeFetch";
import { serverURL } from "../appConfig";
import get from "lodash.get";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { timingSafeEqual } from "crypto";
import { trackPromise } from "react-promise-tracker";

class StepOne extends Component {
  state = {
    companies: [],
    categories: [],
    loading: false,
  };

  componentDidMount() {
    const token = getToken();
    flureeFetch(
      "multi-query",
      {
        company: { select: ["company/name"], from: "company" },
        category: { select: ["displayName", "descriptiveName"], from: "category" },
      },
      token
    )
      .then((res) => {
        let companyArr = [];
        let companies = get(res, "company");
        let categories = get(res, "category");
        let categoryArr = [];

        companies.map((response) => {
          let company = get(response, "company/name");
          companyArr.push(company);
        });

        categories.map((response) => {
          categoryArr.push(response);
        });

        this.setState({ companies: companyArr, categories: categoryArr });
      })
      .catch((err) => {
        this.setState({ error: err });
      });
  }

  submit() {
    this.setState({ loading: true });
    const { name, tagline, cashtag, company, budget, category } = this.state;
    const transaction = [
      {
        _id: "ad$1",
        finished: false,
        id: '#(+ 1 (max-pred-val "ad/id"))',
        name: name,
        tagline: tagline,
        cashtag: cashtag,
        company: ["company/name", company],
        categories: [["category/descriptiveName", category]],
        budget: Number(budget),
        budgetRemaining: Number(budget),
      },
    ];

    const token = getToken();
    flureeFetch("transact", transaction, token)
      .then((res) => {
        const _id = get(res, ["tempids", "ad$1"]);
        this.props.setId(_id);
      })
      .then((res) => this.props.changeStep(2))
      .catch((err) => this.setState({ err: err }));
  }

  render() {
    const { name, tagline, cashtag, company, companies, budget, loading, categories } = this.state;
    return (
      <div>
        <div className="col-xs-3"></div>
        <div className="col-xs-6">
          <h3>Create Your Ad</h3>
          <p>Step 1 - Set Your ad's name, tagline, cashtag, and company.</p>
          <div>
            {this.state.error ? (
              <div>
                <p style={{ color: "red", marginTop: "20px", fontSize: "14px" }}>
                  {this.state.error.message}
                </p>
              </div>
            ) : null}
          </div>
          <Form>
            <Form.Group controlId="name">
              <Form.Label>Ad Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ad Name"
                value={name}
                onChange={(e) => this.setState({ name: e.target.value })}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="tagline">
              <Form.Label>Tagline</Form.Label>
              <Form.Control
                type="text"
                placeholder="Tagline"
                value={tagline}
                onChange={(e) => this.setState({ tagline: e.target.value })}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="cashtag">
              <Form.Label>Cashtag</Form.Label>
              <Form.Control
                type="text"
                placeholder="$Cashtag"
                value={cashtag}
                onChange={(e) => this.setState({ cashtag: e.target.value })}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="budget">
              <Form.Label>Budget</Form.Label>
              <Form.Control
                type="number"
                placeholder="Budget"
                value={budget}
                onChange={(e) => this.setState({ budget: e.target.value })}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="company">
              <Form.Label>Company</Form.Label>
              <Form.Control
                as="select"
                value={this.state.company}
                onChange={(e) => this.setState({ company: e.target.value })}
              >
                <option selected disabled>
                  Select a Company
                </option>
                {companies.map((company) => (
                  <option value={company}>{company}</option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="category">
              <Form.Label>Category</Form.Label>
              <Form.Control
                as="select"
                value={this.state.category}
                onChange={(e) => this.setState({ category: e.target.value })}
              >
                <option selected disabled>
                  Select a Category
                </option>
                {categories.map((category) => (
                  <option value={category["descriptiveName"]}>
                    {category["displayName"]}: {category["descriptiveName"]}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
          <div className="text-center">
            <Button
              onClick={() => this.submit()}
              disabled={!name || !tagline || !cashtag || !company || loading}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

class StepTwo extends Component {
  state = {
    loading: false,
  };

  componentDidMount() {}

  componentWillUnmount() {}

  handleFile(stateKey, e) {
    const reader = new FileReader();
    const file = e.target.files[0];
    if (stateKey === "video") {
    }

    if (file) {
      reader.onload = (upload) => {
        let state = {};
        state[stateKey + "Data_uri"] = upload.target.result;
        state[stateKey + "Filetype"] = file.type;
        state[stateKey + "File"] = file;
        this.setState(state);
      };

      reader.readAsDataURL(file);
    }
  }

  submit = (e) => {
    e.preventDefault();
    this.props.beginLoading("Uploading Cover Image to Server...");
    const {
      coverImageData_uri,
      coverImageFiletype,
      thumbnailData_uri,
      thumbnailFiletype,
      videoData_uri,
      videoFiletype,
    } = this.state;

    const { id } = this.props;

    const token = getToken();

    let coverImageFileSuffix = "." + coverImageFiletype.substring(6);
    let thumbnailFileSuffix = "." + thumbnailFiletype.substring(6);
    let videoFileSuffix = "." + videoFiletype.substring(6);

    let index = /base64,/.exec(videoData_uri).index + 7;
    // let videoSize = atob(videoData_uri.substr(index)).length;
    // if (videoSize > 10000000) {
    //   this.props.endLoading("Uploading Cover Image to Server...");
    //   this.setState({ error: "Video must be less than 10mb" });
    // } else
    if (
      thumbnailFileSuffix !== undefined &&
      coverImageFileSuffix !== undefined &&
      id !== undefined
    ) {
      const videoFileName = `ad/${id}/video${videoFileSuffix}`;
      const coverImageFileName = `ad/${id}/coverImage${coverImageFileSuffix}`;
      const thumbnailFileName = `ad/${id}/thumbnail${thumbnailFileSuffix}`;

      // fetch(`${serverURL}s3/upload-file`, {
      //     method: "POST",
      //     headers: { 'Content-Type': 'application/json'},
      //     body: JSON.stringify({
      //         buffer: coverImageData_uri,
      //         name: coverImageFileName,
      //         type: coverImageFiletype
      //     })})
      trackPromise(
        fetch(`${serverURL}generatepresignedurl`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            name: coverImageFileName,
            type: coverImageFiletype,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            if (!res.success) {
              throw new Error(res.message);
              return;
            }
            const coverImamgeBuf = new Buffer(
              coverImageData_uri.replace(/^data:\w+\/\w+;base64,/, ""),
              "base64"
            );
            return fetch(res.urls[0], {
              method: "PUT",
              headers: { "Content-Type": coverImageFiletype },
              reportProgress: true,
              body: coverImamgeBuf,
            });
          })
          .then((res) => {
            this.props.endLoading("Uploading Cover Image to Server...");
            // fetch(`${serverURL}s3/upload-file`, {
            //     method: "POST",
            //     headers: { 'Content-Type': 'application/json'},
            //     body: JSON.stringify({
            //         buffer: thumbnailData_uri,
            //         name: thumbnailFileName,
            //         type: thumbnailFiletype
            //     })}))
            return fetch(`${serverURL}generatepresignedurl`, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                name: thumbnailFileName,
                type: thumbnailFiletype,
              }),
            });
          })
          .then((res) => {
            this.props.beginLoading("Uploading Thumbnail Image to Server...");
            return res.json();
          })
          .then((res) => {
            if (!res.success) {
              throw new Error(res.message);
              return;
            }
            const thumbnailBuf = new Buffer(
              thumbnailData_uri.replace(/^data:\w+\/\w+;base64,/, ""),
              "base64"
            );
            return fetch(res.urls[0], {
              method: "PUT",
              headers: { "Content-Type": thumbnailFiletype },
              reportProgress: true,
              body: thumbnailBuf,
            });
          })
          .then((res) => {
            this.props.endLoading("Uploading Thumbnail Image to Server...");
            return fetch(`${serverURL}generatepresignedurl`, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                name: videoFileName,
                type: videoFiletype,
              }),
            });
            // const data = new FormData();
            // data.append('file', videoData_uri);
            // data.append('filename', videoFileName)
            // return fetch(`${serverURL}s3/upload-video`,
            //     {
            //         method: "POST",
            //         body: data
            //     })
          })
          .then((res) => {
            this.props.beginLoading("Uploading Video to Server...");
            return res.json();
          })
          .then((res) => {
            if (!res.success) {
              throw new Error(res.message);
              return;
            }
            const videoBuf = new Buffer(
              videoData_uri.replace(/^data:\w+\/\w+;base64,/, ""),
              "base64"
            );
            return fetch(res.urls[0], {
              method: "PUT",
              headers: { "Content-Type": videoFiletype },
              reportProgress: true,
              body: videoBuf,
            });
          })
          .then((res) => {
            let transaction = [
              {
                _id: id,
                video: videoFileName,
                thumbnail: thumbnailFileName,
                coverImage: coverImageFileName,
              },
            ];

            flureeFetch("transact", transaction, token)
              .then((res) => {
                this.props.endLoading("Uploading Video to Server...");
                return this.props.changeStep(3);
              })
              .catch((err) => this.setState({ error: err }));
          })
          .catch((err) => {
            this.props.endLoading();
            this.setState({ err: JSON.stringify(err) });
          })
      );
    } else {
      this.props.endLoading("Uploading Cover Image to Server...");
      this.setState({ error: "You must complete all fields." });
    }
  };

  render() {
    const { coverImageData_uri, thumbnailData_uri, videoData_uri, loading } = this.state;
    return (
      <div>
        <div className="col-xs-3"></div>
        <div className="col-xs-6">
          <h3>Create Your Ad</h3>
          <p>Step 2 - Upload your ad's cover image, thumbnail, and video.</p>
          {this.state.error ? <div style={{ fontColor: "red" }}> {this.state.error} </div> : null}
          <Form className="mt-3" onSubmit={this.submit}>
            <Form.Group controlId="name">
              <Form.Label>Cover Image</Form.Label>
              {coverImageData_uri ? (
                <img
                  style={{ maxHeight: "100px", display: "block", maxWidth: "100px" }}
                  src={coverImageData_uri}
                />
              ) : null}
              <Form.Control
                type="file"
                onChange={(e) => this.handleFile("coverImage", e)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="thumbnail">
              <Form.Label>Thumbnail</Form.Label>
              {thumbnailData_uri ? (
                <img
                  style={{ maxHeight: "100px", display: "block", maxWidth: "100px" }}
                  src={thumbnailData_uri}
                />
              ) : null}
              <Form.Control
                type="file"
                onChange={(e) => this.handleFile("thumbnail", e)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="video">
              <Form.Label>Video</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => this.handleFile("video", e)}
              ></Form.Control>
            </Form.Group>
            <div className="text-center">
              <Button
                type="submit"
                disabled={!coverImageData_uri || !thumbnailData_uri || !videoData_uri || loading}
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

class StepThree extends Component {
  state = {
    questions: [],
    videoPoints: "100",
    postPoints: "50",
    loading: false,
  };

  componentDidMount() {}

  submit() {
    this.setState({ loading: true });
    const { id } = this.props;
    const token = getToken();
    let { videoPoints, questions, postPoints } = this.state;
    questions = questions.map((question) => {
      if (question.type === "YesNo") {
        question["answerChoices"] = ["No", "Yes"];
      }
      if (question.type !== "Scale") {
        question["numAnswers"] = question.answerChoices.length;
      }

      return {
        ...question,
        _id: `question$${question.priority}`,
        numAnswers: Number(question.numAnswers),
        priority: Number(question.priority),
        answerPoints: Number(question.answerPoints),
      };
    });

    if (
      !videoPoints ||
      !postPoints ||
      !questions[0] ||
      questions.some((question) => !question.answerChoices[0])
    ) {
      this.setState((prevState) => ({
        ...prevState,
        error: { message: "Please provide all information." },
        loading: false,
      }));
      return;
    }
    let transaction = [
      {
        _id: id,
        finished: true,
        videoPoints: Number(videoPoints),
        postPoints: Number(postPoints),
      },
    ];
    transaction[0]["questions"] = questions;
    flureeFetch("transact", transaction, token)
      .then((res) => this.props.history.push("/"))
      .catch((err) => this.setState({ err: err }));
  }

  changeAnswers(answers, priority) {
    let questions = this.state.questions;
    let question = questions.find((obj) => obj.priority === Number(priority));
    question["answerChoices"] = answers;
    this.setState((prevState) => ({ ...prevState, questions: questions }));
  }

  handleChange(e) {
    let targetId = e.target.id;
    let key = targetId.slice(0, -1);
    let priority = targetId.slice(-1);
    let questions = this.state.questions;
    let question = questions.find((obj) => obj.priority === Number(priority));
    question[key] = e.target.value;
    this.setState((prevState) => ({ ...prevState, questions: questions }));
  }

  addQuestion = () => {
    this.setState((prevState) => {
      if (!prevState.questions[0]) {
        return {
          ...prevState,
          questions: [{ priority: 1, answerChoices: [], answerPoints: 30, type: "Select" }],
        };
      }
      const lastPriority = prevState.questions[0].priority;
      return {
        ...prevState,
        questions: [
          {
            priority: lastPriority + 1,
            answerChoices: [],
            answerPoints: 30,
            type: "Select",
            label: "",
          },
        ].concat(prevState.questions),
      };
    });
  };

  render() {
    const { questions } = this.state;

    return (
      <div>
        <div className="col-xs-3"></div>
        <div className="col-xs-6">
          <h3>Create Your Ad</h3>
          <p>Step 3 - Set your ad's questions, point values, and (if relevant) correct answers</p>
          <div>
            {this.state.error ? (
              <div style={{ color: "red", marginTop: "20px", fontSize: "14px" }}>
                <p>{this.state.error.message}</p>
              </div>
            ) : null}
          </div>
          <Form>
            <Form.Group controlId={`videoPoints`}>
              <Form.Label>Video Points</Form.Label>
              <Form.Control
                type="number"
                placeholder={10}
                value={this.state.videoPoints}
                onChange={(e) => this.setState({ ...this.state, videoPoints: e.target.value })}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId={`postPoints`}>
              <Form.Label>Social Media Points</Form.Label>
              <Form.Control
                type="number"
                placeholder={10}
                value={this.state.postPoints}
                onChange={(e) => this.setState({ ...this.state, postPoints: e.target.value })}
              ></Form.Control>
            </Form.Group>
            <Button onClick={() => this.addQuestion()}>Add Question</Button>
            {questions.map((question) => {
              return (
                <>
                  <h3>Question {question.priority.toString()}</h3>
                  <Form.Group controlId={`type${question.priority}`}>
                    <Form.Label>Question Type</Form.Label>
                    <Form.Control
                      as="select"
                      value={question.type}
                      onChange={(e) => this.handleChange(e)}
                    >
                      <option value="Select" selected disabled>
                        Select a Question Type
                      </option>
                      <option value="Test">Test User</option>
                      <option value="Action">Call to Action</option>
                      <option value="YesNo">Yes/No</option>
                      <option value="Scale">Scale of Interest</option>
                    </Form.Control>
                  </Form.Group>
                  {question.type !== "Select" && (
                    <>
                      <Form.Group controlId={`label${question.priority}`}>
                        <Form.Label>Question about Video</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Who was the main character in the video?"
                          value={question.label}
                          onChange={(e) => this.handleChange(e)}
                        ></Form.Control>
                      </Form.Group>
                      {question.type === "Action" && (
                        <Form.Group controlId={`actionURI${question.priority}`}>
                          <Form.Label>URL for Action Redirect</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="e.g. https://www.pgeveryday.com/brands/tide"
                            value={question.actionURI}
                            onChange={(e) => this.handleChange(e)}
                          ></Form.Control>
                        </Form.Group>
                      )}
                      {question.type === "Scale" && (
                        <Form.Group controlId={`numAnswers${question.priority}`}>
                          <Form.Label>Number of Answers</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder={3}
                            value={question.numAnswers}
                            onChange={(e) => this.handleChange(e)}
                          ></Form.Control>
                        </Form.Group>
                      )}
                      {question.type !== "YesNo" && (
                        <Form.Group controlId={`answerChoices${question.priority}`}>
                          <Form.Label>Answer Choices</Form.Label>
                          <TagsInput
                            value={question.answerChoices}
                            onChange={(tags) => this.changeAnswers(tags, question.priority)}
                          />
                        </Form.Group>
                      )}
                      {question.type === "Test" && (
                        <Form.Group controlId={`correctAnswer${question.priority}`}>
                          <Form.Label>Correct Answer</Form.Label>
                          <Form.Control
                            as="select"
                            value={question.correctAnswer}
                            onChange={(e) => this.handleChange(e)}
                          >
                            <option selected disabled>
                              Select an Answer
                            </option>
                            {question.answerChoices.map((choice) => (
                              <option value={choice}>{choice}</option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      )}
                      <Form.Group controlId={`answerPoints${question.priority}`}>
                        <Form.Label>Question Points</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder={30}
                          value={question.answerPoints}
                          onChange={(e) => this.handleChange(e)}
                        ></Form.Control>
                      </Form.Group>
                    </>
                  )}
                </>
              );
            })}
          </Form>
          <div className="text-center">
            <Button onClick={() => this.submit()}>Submit</Button>
          </div>
        </div>
      </div>
    );
  }
}

class AddAd extends Component {
  state = {
    step: 1,
  };

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-xs-9" style={{ height: "100px" }}>
            {/* <a className="navbar-brand" href="index.html" style={{paddingTop: 3, paddingLeft: 15}}>
                            <img src={require("../assets/img/fabric_logo_lg.png")} height="55" alt="We are Fabric" />
                        </a> */}
          </div>
          <div className="col-sm-3" style={{ marginTop: "20px" }}>
            <Button onClick={() => this.props.history.push("/")}>Back to Admin Console</Button>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-3"></div>
          <div className="col-xs-6">
            <Steps step={this.state.step} stepMax={3} />
          </div>
          <div className="col-xs-3"></div>
        </div>
        <div className="row">
          {this.state.step === 1 ? (
            <StepOne
              {...this.props}
              changeStep={(s) => this.setState({ step: s })}
              setId={(id) => this.setState({ id: id })}
            />
          ) : null}
          {this.state.step === 2 ? (
            <StepTwo
              {...this.props}
              changeStep={(s) => this.setState({ step: s })}
              id={this.state.id}
            />
          ) : null}
          {this.state.step === 3 ? (
            <StepThree
              {...this.props}
              changeStep={(s) => this.setState({ step: s })}
              id={this.state.id}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default AddAd;
