import React, { Component, useState, useEffect } from "react";
import get from "lodash.get";
import { Link } from "react-router-dom";
import { s3Bucket } from "../../appConfig";
import { NavLink } from "react-router-dom";
import { Button } from "react-bootstrap";
import ErrorBoundary from "../../components/ErrorBoundary";
import MobileTopNav from "../MobileTopNav";

import { Player, ControlBar, TimeDivider, BigPlayButton } from "video-react";
import CurrentTimeDisplay from "video-react/lib/components/time-controls/CurrentTimeDisplay";
import PlayToggle from "video-react/lib/components/control-bar/PlayToggle";
import DurationDisplay from "video-react/lib/components/time-controls/DurationDisplay";
import Shortcut from "video-react/lib/components/Shortcut";

import { flureeFetch, getToken, parseJSON } from "../../flureeFetch";
import { serverURL } from "../../appConfig";
import Font from "../../constants/FontSize";
import NavIcons from "../NavIcons";
import CloseIcon from "@material-ui/icons/Close";
import coinsImg from "../../assets/img/dashboard/coins.png";
import twitterImg from "../../assets/img/dashboard/twitter.png";
import lockImg from "../../assets/img/navSec/lock.png";

import dummyAds from "../../dummyAds";
import { makeStyles, Grid, Card, IconButton, Paper, Modal } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { queryAdsForUser } from "../../utils/functions";
import ShareIcon from "@material-ui/icons/Share";
import { forward } from "video-react/lib/actions/player";

const assetBaseURL = `https://${s3Bucket}.s3.amazonaws.com`;

const lineHeight = (size) => String(1.3 * size) + "px";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: "32px 45px",
    marginBottom: 50,
    marginTop: 75,
    background: "#D5EFFC 0% 0% no-repeat padding-box",
    borderRadius: 32,
    opacity: 1,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  headerLeft: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  companyLogoContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#4096D2",
    borderRadius: 50,
    marginRight: 10,
    height: 100,
    width: 100,
    overflow: "hidden",
  },
  companyLogo: { objectFit: "scale-down", width: 90 },
  companyInfoContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  companyName: {
    fontFamily: "Open Sans",
    fontSize: 30,
    lineHeight: "41px",
    color: "#282560",
    letterSpacing: 0,
  },
  companyCashtag: {
    fontFamily: "Open Sans",
    fontSize: 25,
    lineHeight: "34px",
    color: "#4096D2",
    letterSpacing: 0,
    fontStyle: "italic",
  },
  headerRight: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  sponsored: {
    fontFamily: "Open Sans",
    fontSize: 25,
    lineHeight: "34px",
    color: "#4096D2",
    letterSpacing: 0,
    fontStyle: "italic",
    textAlign: "end",
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  tagline: {
    flex: 1,
    fontFamily: "Open Sans",
    fontSize: 18,
    lineHeight: "25px",
    color: "#282560",
    letterSpacing: 0,
    textAlign: "left",
  },
  cashContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: 10,
  },
  coinsImg: { width: 35, height: 35 },
  cashText: {
    fontFamily: "Open Sans",
    fontSize: 15,
    lineHeight: "20px",
    letterSpacing: 0,
    opacity: 1,
  },
  shareContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    marginLeft: 10,
  },
  tweetImgContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "#29AAE1 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: 25,
    width: 50,
    height: 50,
  },
  twitterImg: { width: 25, height: 25 },
  tweetText: {
    fontFamily: "Open Sans",
    fontSize: 12,
    lineHeight: "17px",
    fontStyle: "italic",
    letterSpacing: 0,
    color: "#282560",
    textAlign: "center",
  },
}));

class Question extends Component {
  state = {
    correct: null,
    bonusQuestionAnswered: false,
    questions:
      this.props.ad.unansweredQuestions &&
      this.props.ad.unansweredQuestions.sort(
        (a, b) => a["question/priority"] - b["question/priority"]
      ),
    answers: [],
  };

  threshold = 5000;

  componentDidUpdate() {
    // if ((this.state.correct === false || this.state.questions.length === 0) && !this.state.completed) {
    //     this.answeredQuestionUpdate()
    // }
  }

  checkAnswer = () => {
    const { correctAnswer } = this.props.ad;
    const { selectedAnswer } = this.state;
    if (correctAnswer === selectedAnswer) {
      this.answeredQuestionUpdate(true);
    } else {
      this.answeredQuestionUpdate(true);
    }
  };

  answeredQuestionUpdate = () => {
    const wallet_id = get(this.props.user, ["user/wallet", "_id"]);
    const balance = get(this.props.user, ["user/wallet", "wallet/balance"]);
    const earnings = get(this.props.user, ["user/wallet", "wallet/earnings"]);

    const validAnswers = this.state.answers.filter((ans) => !(ans.isCorrect === false));
    const invalidAnswer = this.state.answers.find((ans) => ans.isCorrect === false);
    const questionPoints = validAnswers.reduce(
      (acc, val) => val.question["question/answerPoints"] + acc,
      0
    );
    const correctAnswers = validAnswers.filter((ans) => ans.isCorrect);
    const dataAnswers = validAnswers.filter((ans) => !ans.isCorrect);

    const ad = this.props.ad;
    const ad_id = ad._id;
    const budgetRemaining = ad.budgetRemaining;
    let { videoPoints } = this.props.ad;
    videoPoints = this.props.watched ? 0 : videoPoints;
    const userAd = this.props.userAd || this.props.userAdId;
    let transaction = [];
    let userAdTx = {
      _id: userAd,
      watched: true,
    };

    if (questionPoints) {
      userAdTx["pointsEarned"] = `#(+ ${videoPoints} ${questionPoints})`;
    }
    if (correctAnswers && correctAnswers[0]) {
      const correctAnswerRefs = correctAnswers.map((ans, index) => ({
        _id: `answer$Data${index}`,
        question: ans.question._id,
        [typeof ans.answer === "string" ? "answerChoice" : "answerValue"]: ans.answer,
      }));
      userAdTx["questionsSucceeded"] = correctAnswerRefs;
    }
    if (dataAnswers && dataAnswers[0]) {
      const dataAnswerRefs = dataAnswers.map((ans, index) => ({
        _id: `answer$${index}`,
        question: ans.question._id,
        [typeof ans.answer === "string" ? "answerChoice" : "answerValue"]: ans.answer,
      }));
      userAdTx["consumerData"] = dataAnswerRefs;
    }
    if (invalidAnswer) {
      const invalidAnswerRef = {
        _id: "answer$wrong",
        question: invalidAnswer.question._id,
        [typeof invalidAnswer.answer === "string" ? "answerChoice" : "answerValue"]:
          invalidAnswer.answer,
      };
      userAdTx["questionFailed"] = invalidAnswerRef;
    }

    if (questionPoints) {
      transaction.push(
        {
          _id: wallet_id,
          balance: `#(+ ${balance} ${questionPoints})`,
          earnings: `#(+ ${earnings} ${questionPoints})`,
        },
        {
          _id: Number(ad_id),
          budgetRemaining: `#(- ${budgetRemaining} ${questionPoints})`,
        }
      );
    }

    transaction.push(userAdTx);
    // debugger;
    if (!userAd) {
      // debugger;
    }
    // console.log({ transaction });
    const authToken = getToken();
    flureeFetch("transact", transaction, authToken)
      .then((res) => {
        // debugger;
        this.setState({ completed: true });
        this.props.refreshUser();
      })
      .catch((err) => {
        // debugger;
        this.setState({ error: err });
      });
  };

  handleChange = (answer) => {
    this.setState({ selectedAnswer: answer });
    setTimeout(() => {
      this.setState((prevState) => {
        const questions = prevState.questions;
        const prevQuestion = questions.shift();
        if (!prevQuestion) {
          return { ...prevState, questions: [] };
        }
        let isCorrect;
        if (prevQuestion["question/type"] === "Test" || prevQuestion["question/type"] === "YesNo") {
          answer = prevQuestion["question/answerChoices"][answer - 1];
        }
        if (prevQuestion["question/correctAnswer"]) {
          isCorrect = prevQuestion["question/correctAnswer"] === answer;
        }
        if (isCorrect === false) {
          this.props.adjustEarnableCoins();
          return {
            ...prevState,
            answers: prevState.answers.concat({
              isCorrect,
              question: prevQuestion,
              answer,
            }),
            correct: false,
          };
        } else {
          this.props.adjustEarnableCoins(prevQuestion["question/answerPoints"]);
          return {
            ...prevState,
            answers: prevState.answers.concat({
              isCorrect,
              question: prevQuestion,
              answer,
            }),
            questions,
            selectedAnswer: null,
          };
        }
      });
      if (
        (this.state.correct === false || this.state.questions.length === 0) &&
        !this.state.completed
      ) {
        this.answeredQuestionUpdate();
      }
    }, 1500);

    // if (this.state.correct) {
    //     setTimeout(() => this.setState({ bonusQuestionAnswered: true }), 1500)
    // } else {
    //     setTimeout(this.checkAnswer, 1500)
    // }
  };

  returnToRequests = () => {
    this.props.history.push("/ad/requests");
  };

  mapAnswers = (answerChoices, numAnswers, fs, actionURI) => {
    let array = Array(numAnswers)
      .fill()
      .map((_, i) => i + 1);
    let everyFlag = numAnswers / answerChoices.length === 1;
    let answersArray = [...answerChoices];
    if (actionURI) {
      return (
        <div
          onClick={() => this.handleChange(numAnswers)}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <a href={actionURI} target="_blank">
            <div
              style={{
                padding: "5px 15px",
                background: "#EF4137 0% 0% no-repeat padding-box",
                opacity: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "none",
                cursor: "pointer",
              }}
            >
              <p
                style={{
                  fontFamily: "PT Sans",
                  fontSize: fs.button,
                  lineHeight: lineHeight(fs.button),
                  letterSpacing: 0,
                  color: "white",
                  opacity: 1,
                }}
              >
                {answerChoices[0]}
              </p>
            </div>
          </a>
        </div>
      );
    }
    return array.map((answer, index) => {
      let answerText;
      if (everyFlag) {
        answerText = answersArray.shift();
      } else if (index === 0) {
        answerText = answersArray[0];
      } else if (index === array.length - 1) {
        answerText = answersArray[answersArray.length - 1];
      }
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            minWidth: String(100 / numAnswers) + "%",
            maxWidth: String(100 / numAnswers) + "%",
          }}
        >
          <div
            style={{
              width: this.props.vw(8),
              height: this.props.vw(8),
              borderRadius: this.props.vw(4),
              border: "3px solid #4096D2",
              backgroundColor: this.state.selectedAnswer === answer ? "#4096D2" : "white",
            }}
            onClick={() => this.handleChange(answer)}
          ></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              flex: 1,
              fontFamily: "PT Sans",
              fontSize: fs.p1,
              lineHeight: lineHeight(fs.p1),
              color: "#282560",
              textAlign: "center",
            }}
          >
            {" "}
            {answerText}{" "}
          </div>
        </div>
      );
    });
  };

  mapAnswersDesktop = (answerChoices, numAnswers, fs, actionURI) => {
    let array = Array(numAnswers)
      .fill()
      .map((_, i) => i + 1);
    let everyFlag = numAnswers / answerChoices.length === 1;
    let answersArray = [...answerChoices];
    if (actionURI) {
      return (
        <div
          onClick={() => this.handleChange(numAnswers)}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <a href={actionURI} target="_blank">
            <div
              style={{
                padding: "5px 15px",
                background: "#EF4137 0% 0% no-repeat padding-box",
                opacity: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "none",
                cursor: "pointer",
              }}
            >
              <p
                style={{
                  fontFamily: "PT Sans",
                  fontSize: fs.button,
                  lineHeight: lineHeight(fs.button),
                  letterSpacing: 0,
                  color: "white",
                  opacity: 1,
                }}
              >
                {answerChoices[0]}
              </p>
            </div>
          </a>
        </div>
      );
    }
    return array.map((answer, index) => {
      let answerText;
      if (everyFlag) {
        answerText = answersArray.shift();
      } else if (index === 0) {
        answerText = answersArray[0];
      } else if (index === array.length - 1) {
        answerText = answersArray[answersArray.length - 1];
      }
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            minWidth: String(100 / numAnswers) + "%",
            maxWidth: String(100 / numAnswers) + "%",
          }}
        >
          <div
            style={{
              width: this.props.vw(4),
              height: this.props.vw(4),
              borderRadius: this.props.vw(2),
              border: "3px solid #4096D2",
              backgroundColor: this.state.selectedAnswer === answer ? "#4096D2" : "white",
            }}
            onClick={() => this.handleChange(answer)}
          ></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              flex: 1,
              fontFamily: "PT Sans",
              fontSize: fs.p1,
              lineHeight: lineHeight(fs.p1),
              color: "#282560",
              textAlign: "center",
            }}
          >
            {" "}
            {answerText}{" "}
          </div>
        </div>
      );
    });
  };

  render() {
    const { videoPoints, postPoints, bonusQuestion, bonusQuestionAnswerChoices } = this.props.ad;
    const question = this.state.questions[0];
    let label, answerChoices, questionPoints, correctAnswer, numAnswers, type, _id, actionURI;
    if (question) {
      label = question["question/label"];
      answerChoices = question["question/answerChoices"];
      // questionPoints = question['question/answerPoints']
      correctAnswer = question["question/correctAnswer"];
      numAnswers = question["question/numAnswers"];
      type = question["question/type"];
      _id = question["question/_id"];
      actionURI = question["question/actionURI"];
    }
    questionPoints = this.state.questions.reduce(
      (acc, val) => val["question/answerPoints"] + acc,
      0
    );
    const { innerWidth, vw } = this.props;
    const fabricURI = window.location.href;
    const cashtag = this.props.ad.cashtag[0];
    const hashtag = cashtag && cashtag[0] === "$" ? cashtag.slice(1) : cashtag;

    let fs = Font.big;
    fs = innerWidth < 768 ? Font.med : fs;
    fs = innerWidth < 350 ? Font.small : fs;
    if (!question || this.props.failed) {
      if (innerWidth < 1025) {
        return (
          <div
            id="twitter-container-b"
            style={{
              display: "flex",
              height: 194,
              // height: '100%',
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
              borderRadius: 20,
              boxShadow: "0px 3px 6px #00000029",
            }}
          >
            <div
              style={{
                fontFamily: "PT Sans",
                width: "80%",
                fontSize: fs.p1,
                fontWeight: "bold",
                lineHeight: lineHeight(fs.p1),
                textAlign: "center",
                color: "#EF4137",
              }}
            >
              Thanks for Participating with This Ad!
            </div>
            <div
              style={{
                fontFamily: "PT Sans",
                width: "80%",
                fontSize: fs.p1,
                fontWeight: "bold",
                lineHeight: lineHeight(fs.p1),
                textAlign: "center",
                color: "#EF4137",
                marginTop: 15,
              }}
            >
              {postPoints && !this.props.shared
                ? `You can share this ad on Twitter for another ${postPoints} CA$H!`
                : "During our Beta phase, users will only be able to participate with a few sample ads. Check back soon to participate with other brands and earn more CA$H"}
            </div>
          </div>
        );
      }
      return (
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            backgroundColor: "#F3F3F3",
            borderRadius: 10,
            boxShadow: "0px 3px 6px #00000029",
            padding: "10px 20px",
            minHeight: "40vh",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              // padding: '20px 50px',
              // backgroundColor: '#EF4137',
              // borderRadius: 15
            }}
          >
            <p
              style={{
                textAlign: "center",
                fontFamily: "Futura-Bold",
                color: "#282560",
                fontSize: 30,
                lineHeight: "41px",
                letterSpacing: 0,
                marginTop: 30,
                marginBottom: 30,
                // color: 'white'
              }}
            >
              Thanks for Participating with This Ad!
            </p>
          </div>
          {postPoints && !this.props.shared && (
            <p
              style={{
                textAlign: "center",
                fontFamily: "Open Sans",
                fontSize: 16,
                lineHeight: "26px",
                letterSpacing: 0,
                color: "#282560",
                marginBottom: 40,
              }}
            >
              You can share this ad on Twitter for another {postPoints} CA$H!
            </p>
          )}
          {/* <p
            style={{
              textAlign: "center",
              fontFamily: "Open Sans",
              fontSize: 16,
              lineHeight: "26px",
              letterSpacing: 0,
              color: "#282560",
              width: "75%",
            }}
          >
            During our Beta phase, users will only be able to participate with this one Fabric ad.
            Check back soon to participate with other brands and earn more CA$H
          </p> */}
        </div>
      );
    }

    if (innerWidth < 1025) {
      return (
        <div
          id="twitter-container-b"
          style={{
            display: "flex",
            height: 194,
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center",
            backgroundColor: "white",
            borderRadius: 20,
            boxShadow: "0px 3px 6px #00000029",
          }}
        >
          {!this.props.watched && this.state.correct === null && !this.state.showQuestion ? (
            <>
              <div
                style={{
                  fontFamily: "PT Sans",
                  width: "80%",
                  fontSize: 14,
                  fontWeight: "bold",
                  lineHeight: lineHeight(fs.p1),
                  textAlign: "center",
                  color: "#EF4137",
                  marginTop: 5,
                  marginBottom: 15,
                }}
              >
                Congrats!
                <br />
                You just earned {videoPoints} CA$H! Earn {questionPoints} more by giving your
                feedback about the ad.
              </div>
              <button
                onClick={() => this.setState({ showQuestion: true })}
                style={{
                  width: vw(30),
                  height: vw(7),
                  background: "#EF4137 0% 0% no-repeat padding-box",
                  opacity: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "none",
                  marginTop: 15,
                }}
              >
                <p
                  style={{
                    fontFamily: "PT Sans",
                    fontSize: fs.button,
                    lineHeight: lineHeight(fs.button),
                    letterSpacing: 0,
                    color: "white",
                    opacity: 1,
                  }}
                >
                  Continue
                </p>
              </button>
            </>
          ) : null}
          {this.props.watched && this.state.correct === null && !this.state.showQuestion ? (
            <>
              <div
                style={{
                  fontFamily: "PT Sans",
                  width: "80%",
                  fontSize: 16,
                  fontWeight: "bold",
                  lineHeight: lineHeight(30),
                  textAlign: "center",
                  color: "#EF4137",
                }}
              >
                You can still earn {questionPoints} CA$H more by giving your feedback about the ad
              </div>
              <button
                onClick={() => this.setState({ showQuestion: true })}
                style={{
                  width: vw(30),
                  height: vw(7),
                  background: "#EF4137 0% 0% no-repeat padding-box",
                  opacity: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "none",
                  marginBottom: 10,
                }}
              >
                <p
                  style={{
                    fontFamily: "PT Sans",
                    fontSize: fs.button,
                    lineHeight: lineHeight(fs.button),
                    letterSpacing: 0,
                    color: "white",
                    opacity: 1,
                  }}
                >
                  Continue
                </p>
              </button>
            </>
          ) : null}
          {this.state.correct === null && this.state.showQuestion ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontFamily: "PT Sans",
                    fontSize: fs.p1,
                    lineHeight: lineHeight(fs.p1),
                    width: "70%",
                    textAlign: "center",
                  }}
                >
                  {label}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  width: "100%",
                }}
              >
                {/* {answerChoices.map((answer, index) => {
                                        answer = answer === '1' ? 'Irrelevant' : answer
                                        answer = answer === '5' ? 'Very Relevant' : answer
                                        return (<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', minWidth: String(100 / answerChoices.length) + '%', maxWidth: String(100 / answerChoices.length) + '%' }}>
                                            <div style={{ width: vw(8), height: vw(8), borderRadius: vw(4), border: '3px solid #4096D2', backgroundColor: this.state.selectedAnswer === answer ? '#4096D2' : 'white' }} onClick={() => this.handleChange(answer)}>

                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: 1, fontFamily: 'PT Sans', fontSize: fs.p1, lineHeight: lineHeight(fs.p1), color: '#282560', textAlign: 'center' }}> {Number(answer) !== Number(answer) ? answer : ''} </div>
                                        </div>)
                                    })} */}
                {this.mapAnswers(answerChoices, numAnswers, fs, actionURI)}
              </div>
            </>
          ) : null}
          {/* {
                        this.state.correct === true && bonusQuestion && !this.state.bonusQuestionAnswered
                            ?
                            <>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                    <p style={{ fontFamily: 'PT Sans', fontSize: fs.p1, lineHeight: lineHeight(fs.p1), width: '70%', textAlign: 'center' }}>{bonusQuestion}</p>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '100%' }}>
                                    {bonusQuestionAnswerChoices.map((answer, index) => {
                                        answer = answer === '1' ? 'Not Interested' : answer
                                        answer = answer === '5' ? 'Very Interested' : answer
                                        return (<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', minWidth: String(100 / bonusQuestionAnswerChoices.length) + '%', maxWidth: String(100 / bonusQuestionAnswerChoices.length) + '%' }}>
                                            <div style={{ width: vw(8), height: vw(8), borderRadius: vw(4), border: '3px solid #4096D2', backgroundColor: this.state.selectedAnswer === answer ? '#4096D2' : 'white' }} onClick={() => this.handleChange(answer)}>

                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: 1, fontFamily: 'PT Sans', fontSize: fs.p1, lineHeight: lineHeight(fs.p1), color: '#282560', textAlign: 'center' }}> {Number(answer) !== Number(answer) ? answer : ''} </div>
                                        </div>)
                                    })}
                                </div>
                            </>
                            :
                            null
                    } */}
          {this.state.correct && (
            <>
              <div
                style={{
                  fontFamily: "PT Sans",
                  width: "80%",
                  fontSize: fs.p1,
                  fontWeight: "bold",
                  lineHeight: lineHeight(fs.p1),
                  textAlign: "center",
                  color: "#EF4137",
                }}
              >
                Thanks for your answers!
                <br />
                You've earned an additional {questionPoints} CA$H!
                <br />
                <br />
                {postPoints ? `Share this post to Twitter for another ${postPoints} CA$H!` : ""}
              </div>
            </>
          )}
          {/* {
                        this.state.correct && this.state.bonusQuestionAnswered &&
                        <>
                            <div style={{ fontFamily: 'PT Sans', width: '80%', fontSize: fs.p1, fontWeight: 'bold', lineHeight: lineHeight(fs.p1), textAlign: 'center', color: '#EF4137' }}>
                                Thanks for your answers!<br />You've earned an additional {questionPoints} CA$H!<br /><br />{postPoints ? `Share this post to Twitter for another ${postPoints} CA$H!` : ''}
                            </div>
                        </>
                    } */}
          {this.state.correct === false && (
            <>
              <div
                style={{
                  fontFamily: "PT Sans",
                  width: "80%",
                  fontSize: fs.p1,
                  fontWeight: "bold",
                  lineHeight: lineHeight(fs.p1),
                  textAlign: "center",
                  color: "#EF4137",
                }}
              >
                Sorry, that was the wrong answer!
                <br />
                <br />
                To earn additional CA$H, you must successfully particpate with brand campaigns.
              </div>
            </>
          )}
        </div>
      );
    }
    return (
      <div
        id="twitter-container-b"
        style={{
          display: "flex",
          flex: 1,
          minWidth: "80%",
          minHeight: "40vh",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          backgroundColor: "white",
          borderRadius: 20,
          padding: "0px 20px",
          boxShadow: "0px 3px 6px #00000029",
        }}
      >
        {!this.props.watched && this.state.correct === null && !this.state.showQuestion ? (
          <>
            <div
              style={{
                fontFamily: "PT Sans",
                width: "80%",
                fontSize: fs.p1,
                fontWeight: "bold",
                lineHeight: lineHeight(fs.p1),
                textAlign: "center",
                color: "#EF4137",
                marginTop: 30,
                marginBottom: 30,
              }}
            >
              Congrats!
              <br />
              You just earned {videoPoints} CA$H! Earn {questionPoints} more by giving your feedback
              about the ad
            </div>
            <button
              onClick={() => this.setState({ showQuestion: true })}
              style={{
                width: 295,
                height: 75,
                background: "#EF4137 0% 0% no-repeat padding-box",
                opacity: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "none",
                marginBottom: 20,
              }}
            >
              <p
                style={{
                  fontFamily: "PT Sans",
                  fontSize: fs.button,
                  lineHeight: lineHeight(fs.button),
                  letterSpacing: 0,
                  color: "white",
                  opacity: 1,
                }}
              >
                Continue
              </p>
            </button>
          </>
        ) : null}
        {this.props.watched && this.state.correct === null && !this.state.showQuestion ? (
          <>
            <div
              style={{
                fontFamily: "PT Sans",
                width: "80%",
                fontSize: fs.p1,
                fontWeight: "bold",
                lineHeight: lineHeight(fs.p1),
                textAlign: "center",
                color: "#EF4137",
                marginTop: 15,
                marginBottom: 20,
              }}
            >
              You can still earn {questionPoints} CA$H more by giving your feedback about the ad
            </div>
            <button
              onClick={() => this.setState({ showQuestion: true })}
              style={{
                width: 295,
                height: 75,
                background: "#EF4137 0% 0% no-repeat padding-box",
                opacity: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "none",
                marginBottom: 20,
              }}
            >
              <p
                style={{
                  fontFamily: "PT Sans",
                  fontSize: fs.button,
                  lineHeight: lineHeight(fs.button),
                  letterSpacing: 0,
                  color: "white",
                  opacity: 1,
                }}
              >
                Continue
              </p>
            </button>
          </>
        ) : null}
        {this.state.correct === null && this.state.showQuestion ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                minWidth: "80%",
                justifyContent: "center",
                alignItems: "center",
                padding: "15px 5px",
              }}
            >
              <p
                style={{
                  fontFamily: "PT Sans",
                  fontSize: fs.p1,
                  lineHeight: lineHeight(fs.p1),
                  width: "85%",
                  textAlign: "center",
                }}
              >
                {label}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                width: "100%",
                marginBottom: 15,
              }}
            >
              {/* {answerChoices.map((answer, index) => {
                                        answer = answer === '1' ? 'Irrelevant' : answer
                                        answer = answer === '5' ? 'Very Relevant' : answer
                                        return (<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', minWidth: String(100 / answerChoices.length) + '%', maxWidth: String(100 / answerChoices.length) + '%' }}>
                                            <div style={{ width: vw(8), height: vw(8), borderRadius: vw(4), border: '3px solid #4096D2', backgroundColor: this.state.selectedAnswer === answer ? '#4096D2' : 'white' }} onClick={() => this.handleChange(answer)}>

                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: 1, fontFamily: 'PT Sans', fontSize: fs.p1, lineHeight: lineHeight(fs.p1), color: '#282560', textAlign: 'center' }}> {Number(answer) !== Number(answer) ? answer : ''} </div>
                                        </div>)
                                    })} */}
              {this.mapAnswersDesktop(answerChoices, numAnswers, fs, actionURI)}
            </div>
          </>
        ) : null}
        {this.state.correct === false && (
          <>
            <div
              style={{
                fontFamily: "PT Sans",
                width: "80%",
                fontSize: fs.p1,
                fontWeight: "bold",
                lineHeight: lineHeight(fs.p1),
                textAlign: "center",
                color: "#EF4137",
                margin: "15px auto",
              }}
            >
              Sorry, that was the wrong answer!
              <br />
              <br />
              To earn additional CA$H, you must successfully particpate with brand campaigns.
            </div>
          </>
        )}
        {/* {
                    this.state.correct === null
                        ?
                        <>
                            <div>
                                <p style={{ fontSize: "12px", marginBottom: "5px", textAlign: 'center', fontFamily: 'PT Sans', color: '#282560' }}>You earned {videoPoints} points for watching this video.</p>
                                <p className="text-center" style={{ textAlign: 'center', fontFamily: 'PT Sans', color: '#282560' }}>Answer this question for +<strong>{questionPoints} SocialCash</strong></p>
                            </div>
                            <h3 className="text-center" style={{ fontWeight: 320 }}>{question}</h3>
                            <div className="text-center">
                                {answerChoices.map(answer =>
                                    <div style={{ display: "inline-block" }}>
                                        <div className="answerCircle" onClick={() => this.handleChange(answer)}>
                                            {
                                                this.state.selectedAnswer === answer
                                                    ?
                                                    <i className="fas fa-check answerCheck"></i>
                                                    :
                                                    null
                                            }
                                        </div>
                                        <div> {answer} </div>
                                    </div>)}
                            </div>
                            <div className="text-center" style={{ marginTop: "10px" }}>
                                <Button onClick={() => this.checkAnswer()}>Submit</Button>
                            </div>
                        </>
                        :
                        null
                } */}
        {/* {
                    this.state.correct === true && bonusQuestion && !this.state.bonusQuestionAnswered
                        ?
                        <>
                            <div>
                                <p style={{ fontSize: "12px", marginBottom: "5px", textAlign: 'center', fontFamily: 'PT Sans', color: '#282560' }}>You earned {questionPoints} points for answering this question.</p>
                                <p className="text-center" style={{ textAlign: 'center', fontFamily: 'PT Sans', color: '#282560' }}>Would you like to answer this bonus question for?</p>
                                <h3 className="text-center" style={{ fontWeight: 320 }}>{bonusQuestion}</h3>
                            </div>
                            <div className="text-center">
                                {bonusQuestionAnswerChoices.map(answer =>
                                    <div style={{ display: "inline-block" }}>
                                        <div className="answerCircle" onClick={() => this.handleChange(answer)}>
                                            {
                                                this.state.selectedAnswer === answer
                                                    ?
                                                    <i className="fas fa-check answerCheck"></i>
                                                    :
                                                    null
                                            }
                                        </div>
                                        <div> {answer} </div>
                                    </div>)}
                            </div>
                            <div className="text-center" style={{ marginTop: "10px" }}>
                                <Button onClick={() => this.setState({ bonusQuestionAnswered: true })}>Submit</Button>
                            </div>
                        </>
                        :
                        null
                } */}
        {/* {
                    this.state.correct && !bonusQuestion &&
                    <div className="text-center">
                        <i className="fas fa-trophy" style={{ fontSize: "100px", color: "#bfbfbf" }}></i>
                        <h2 style={{ fontWeight: 300 }}>Congratulations!</h2>
                        <h2 style={{ fontWeight: 300 }}>You have earned <strong>{questionPoints} Social Cash Tokens!</strong></h2>
                        <div><Button onClick={this.returnToRequests}>Return to Requests</Button></div>
                        {postPoints && !this.props.sharedFlag && <><h2>-- or --</h2> <h2>Tweet About This Ad for Another <strong>{postPoints} Social Cash Tokens!</strong></h2></>}

                    </div>
                } */}
        {/* {
                    this.state.correct && this.state.bonusQuestionAnswered &&
                    <div className="text-center">
                        <i className="fas fa-trophy" style={{ fontSize: "100px", color: "#bfbfbf" }}></i>
                        <h2 style={{ fontWeight: 300 }}>Thank you for answering this bonus question!</h2>
                        <h2 style={{ fontWeight: 300 }}>You have earned <strong>{questionPoints} Social Cash Tokens!</strong></h2>
                        <div><Button onClick={this.returnToRequests}>Return to Requests</Button></div>
                        {postPoints && !this.props.sharedFlag && <><h2>-- or --</h2> <h2>Tweet About This Ad for Another <strong>{postPoints} Social Cash Tokens!</strong></h2></>}

                    </div>
                } */}
      </div>
    );
  }
}

class AdVideo extends Component {
  state = {
    completed: false,
    userAd_id: this.props.userAdId,
  };

  componentDidMount() {
    this.refs.player.subscribeToStateChange(this.handleStateChange.bind(this));
  }

  watchedVideoUpdate = (points) => {
    if (this.props.watched) {
      // debugger;
      return;
    }
    const ad = this.props.ad;
    const userAdId = this.props.userAdId;
    const ad_id = ad._id;
    const adBudgetRemaining = ad.budgetRemaining;
    const wallet_id = get(this.props.user, ["user/wallet", "_id"]);
    const balance = get(this.props.user, ["user/wallet", "wallet/balance"]);
    const earnings = get(this.props.user, ["user/wallet", "wallet/earnings"]);
    let transaction;
    // debugger;
    if (userAdId) {
      transaction = [
        {
          _id: wallet_id,
          balance: `#(+ ${balance} ${points})`,
          earnings: `#(+ ${earnings} ${points})`,
        },
        {
          _id: userAdId,
          watched: true,
          watchDate: "#(now)",
          pointsEarned: points,
        },
        {
          _id: Number(ad_id),
          budgetRemaining: `#(- ${adBudgetRemaining} ${points})`,
        },
      ];
      // debugger
    } else {
      transaction = [
        {
          _id: wallet_id,
          balance: `#(+ ${balance} ${points})`,
          earnings: `#(+ ${earnings} ${points})`,
        },
        {
          _id: "userAd$1",
          ad: Number(ad_id),
          watched: true,
          watchDate: "#(now)",
          pointsEarned: points,
        },
        {
          _id: Number(ad_id),
          budgetRemaining: `#(- ${adBudgetRemaining} ${points})`,
        },
        {
          _id: this.props.user._id,
          userAds: ["userAd$1"],
        },
      ];
    }
    // debugger;
    const authToken = getToken();
    flureeFetch("transact", transaction, authToken)
      .then((res) => {
        let userAd_id = get(res, ["tempids", "userAd$1"]);
        // debugger
        this.props.adjustEarnableCoins(points);
        this.props.refreshUser();
        this.setState({ userAd_id: userAd_id || this.props.userAdId });
        // debugger;
      })
      .then((res) => {
        // debugger
        //  console.log('success!', parseJSON(res));
      })

      .then((res) => this.props.refreshUser())
      .catch((err) => this.setState({ error: err }));
  };

  handleStateChange(state, prevState) {
    if (state.currentTime === state.duration && state.completed !== true) {
      this.setState({ completed: true });
      const { videoPoints } = this.props.ad;
      this.watchedVideoUpdate(videoPoints);
    }
  }

  // Override 'l' to jump forward
  // Override 'Shift + >' to increase speed
  // Ctrl/Cmd + Right arrow to go forward 30 seconds
  newShortcuts = [
    { keyCode: 76, handle: () => {} },
    { keyCode: 190, shift: true, handle: () => {} },
    { keyCode: 39, handle: () => {} },
    {
      keyCode: 39,
      ctrl: true,
      handle: (player, actions) => {
        const operation = { action: "forward-30", source: "shortcut" };
        actions.forward(2000, operation);
      },
    },
  ];

  render() {
    const { forwardedRef, ad, user, refreshUser, vh, vw, innerWidth } = this.props;
    const { userAd_id } = this.state;
    const { coverImage, videoURL } = ad;
    let coverURL, sourceURL;
    if (coverImage) {
      coverURL = coverImage.startsWith("http")
        ? coverImage
        : `https://${s3Bucket}.s3.amazonaws.com/` + coverImage;
    }
    if (videoURL) {
      sourceURL = videoURL.startsWith("http")
        ? videoURL
        : `https://${s3Bucket}.s3.amazonaws.com/` + videoURL;
    }
    if (innerWidth < 1025) {
      return (
        <div
          style={{
            minHeight: vw(49),
            maxHeight: vh(30),
            width: vw(89.9),
            width: "100%",
            display: "-webkit-box",
            display: "-moz-box",
            display: "-ms-flexbox",
            display: "-webkit-flex",
            display: "flex", // position: 'relative',
            flexDirection: "column",
            alignItems: "stretch",
            marginTop: vw(2),
            marginBottom: vw(2),
          }}
        >
          {this.state.completed ? (
            <div
              style={{
                display: "-webkit-box",
                display: "-moz-box",
                display: "-ms-flexbox",
                display: "-webkit-flex",
                display: "flex", // position: 'relative',
                height: 192,
                width: "100%",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "3% 7%",
                backgroundColor: "#C4C4C4",
                marginBottom: 10,
              }}
            >
              <Question
                ad={ad}
                user={user}
                userAd={userAd_id}
                refreshUser={refreshUser}
                {...this.props}
              />
            </div>
          ) : (
            <div style={{}}>
              <Player
                poster={coverURL}
                startTime={0}
                videoId="vid-player"
                ref="player"
                clickable={false}
                onKeyPress={this.handleKeyPress}
                fluid={false}
                width={vw(90)}
                height={vh(25)}
                playsInline
              >
                <source src={sourceURL} />
                <Shortcut shortcuts={this.newShortcuts} />
                <ControlBar disableDefaultControls={true} disableCompletely>
                  <PlayToggle />
                  <CurrentTimeDisplay />
                  <TimeDivider />
                  <DurationDisplay />
                </ControlBar>
              </Player>
            </div>
          )}
        </div>
      );
    }
    return (
      <div
        ref={forwardedRef}
        style={{
          width: "auto",
          // minWidth: 840,
          // maxWidth: vw(56),
          maxHeight: vw(31),
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 15,
          marginBottom: 25,
          backgroundColor: "#000000",
        }}
      >
        {this.state.completed ? (
          <div
            style={{
              display: "flex",
              flex: 1,
              width: "100%",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "3% 7%",
              backgroundColor: "#C4C4C4",
            }}
          >
            <Question
              ad={ad}
              user={user}
              userAd={userAd_id}
              refreshUser={refreshUser}
              {...this.props}
            />
          </div>
        ) : (
          <Player
            poster={coverURL}
            startTime={0}
            videoId="vid-player"
            ref="player"
            clickable={false}
            onKeyPress={this.handleKeyPress}
            fluid={false}
            height={vh(90)}
          >
            <BigPlayButton position="center" />
            <source src={sourceURL} />
            <Shortcut shortcuts={this.newShortcuts} />
            <ControlBar disableDefaultControls={true} autoHide={true}>
              <PlayToggle />
              <CurrentTimeDisplay />
              <TimeDivider />
              <DurationDisplay />
            </ControlBar>
          </Player>
        )}
      </div>
    );
  }
}

class Request extends Component {
  getModalStyle = () => {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  };

  state = {
    open: false,
    modalStyle: this.getModalStyle,
  };

  threshold = 5000;

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  adjustEarnableCoins = (subtractor) => {
    this.setState((prevState) => ({
      ...prevState,
      earnableCoins:
        prevState.earnableCoins - (subtractor === undefined ? prevState.earnableCoins : subtractor),
    }));
  };

  handleTweet = (e) => {
    // window.twttr.events.unbind('tweet')
    // const iframe = document.querySelector('iframe')
    // iframe && iframe.remove()
    if (e.target.dataset.postpoints === "0") {
      return;
    } else {
      this.addPoints(Number(e.currentTarget.dataset.postpoints), e.currentTarget.id, "Twitter")
        .then((res) => {
          this.setState({ sharedOnTwitter: true });
        })
        .catch((err) => {
          return;
        });
    }
  };

  addPoints = (points, adId, socialMedia) => {
    if (this.state.addingPoints > 0) {
      // debugger;
      return new Promise((res, rej) => res("success"));
    }
    this.setState((prevState) => {
      return {
        ...prevState,
        addingPoints: prevState.addingPoints ? prevState.addingPoints + 1 : 1,
        updatingPost: { adId },
      };
    });
    const userId = this.props.user._id;
    const walletId = get(this.props.user, ["user/wallet", "_id"]);
    const balance = get(this.props.user, ["user/wallet", "wallet/balance"]);
    const earnings = get(this.props.user, ["user/wallet", "wallet/earnings"]);
    const state = this.state;
    const adData = this.props.ad;
    const budgetRemaining = adData["ad/budgetRemaining"];
    // if (!this.props.user['user/userAds']) {
    //     throw new Error("Loop again and return")
    // }
    let userAdId;
    // debugger;

    if (!!this.props.user["user/userAds"]) {
      userAdId = this.props.user["user/userAds"].find((ad) => {
        return ad["userAd/ad"]["_id"] === parseInt(adId);
      });
      userAdId = userAdId ? userAdId["_id"] : null;
    }

    let transaction = [
      {
        _id: Number(walletId),
        balance: `#(+ ${balance} ${points})`,
        earnings: `#(+ ${earnings} ${points})`,
      },
    ];

    let tx2;
    let tx3;
    if (userAdId) {
      tx2 = {
        _id: Number(userAdId),
        sharedOnTwitter: true,
      };
    } else {
      tx2 = {
        _id: userId,
        userAds: ["userAd$new"],
      };
      tx3 = {
        _id: "userAd$new",
        sharedOnTwitter: true,
        ad: Number(adId),
      };
    }

    // tx2[`sharedOn${socialMedia}`] = true;

    const tx4 = {
      _id: Number(adId),
      budgetRemaining: `#(- ${budgetRemaining} ${points})`,
    };

    transaction.push(tx2);
    if (tx3) {
      transaction.push(tx3);
    }
    transaction.push(tx4);
    const authToken = getToken();

    return flureeFetch("transact", transaction, authToken)
      .then((res) => {
        // let newState = {}
        // newState[`sharedOn${socialMedia}`] = true;
        // this.setState(newState);
        // debugger;
        if (res.tempids && res.tempids["userAd$new"]) {
          this.setState((prevState) => {
            return {
              ...prevState,
              updatingPost: {
                ...prevState.updatingPost,
                userAd: res.tempids["userAd$new"],
              },
            };
          });
        }
        this.setState({ addingPoints: null });
        this.adjustEarnableCoins(points);
        return;
      })
      .then((res) => {
        // debugger;
        this.props.refreshUser();
      })
      .catch((err) => {
        // debugger;
        this.setState({ error: err });
      });
  };

  componentDidMount() {
    const { ad } = this.props;
    const userAd = ad.userAd;
    let failedFlag, sharedFlag, watchedFlag, questionsSucceeded, consumerData, answeredQuestions;
    if (userAd) {
      sharedFlag = userAd["userAd/sharedOnTwitter"];
      watchedFlag = userAd["userAd/watched"];
      failedFlag = userAd.questionFailed;
      questionsSucceeded =
        userAd.questionsSucceeded &&
        userAd.questionsSucceeded.map((ans) => ans["answer/question"]._id);
      consumerData =
        userAd.consumerData && userAd.consumerData.map((ans) => ans["answer/question"]._id);
      answeredQuestions =
        (questionsSucceeded && consumerData && questionsSucceeded.concat(consumerData)) ||
        questionsSucceeded ||
        consumerData;
    }
    const unansweredQuestions = ad.questions.filter(
      (ques) => !(answeredQuestions && answeredQuestions.includes(ques._id))
    );
    // const answeredQuestions = userAd && (userAd['userAd/questionsSucceeded'] && userAd['userAd/consumerData'] && userAd['userAd/questionsSucceeded'].concat(userAd['userAd/consumerData']) || userAd['userAd/questionsSucceeded'] || userAd['userAd/consumerData'])
    // const answeredQuestionIds = answeredQuestions && answeredQuestions.map(ans => ans._id)

    // const unansweredQuestions = ad.questions.filter(question => !(answeredQuestionIds && answeredQuestionIds.includes(question._id)))
    const adData = ad;
    const _id = ad._id;
    let userAdId = userAd && userAd._id;
    if (
      this.state.updatingPost &&
      this.state.updatingPost.userAd &&
      Number(this.state.updatingPost.adId) === _id
    ) {
      userAdId = this.state.updatingPost.userAd;
    }
    //can eventually delete from here...
    const question = adData["ad/question"];
    const questionPoints = adData["ad/questionPoints"];
    const answerChoices = adData["ad/answerChoices"];
    const correctAnswer = adData["ad/correctAnswer"];
    const bonusQuestion = adData["ad/bonusQuestion"];
    const bonusQuestionPoints = adData["ad/bonusQuestionPoints"];
    const bonusQuestionAnswerChoices = adData["ad/bonusQuestionAnswerChoices"];
    //..to here
    const videoPoints = adData["ad/videoPoints"];
    const postPoints = adData["ad/postPoints"];
    const budgetRemaining = adData["ad/budgetRemaining"];
    const coverImage = adData["ad/coverImage"];
    const cashtag = adData["ad/cashtag"];
    const tagline = adData["ad/tagline"];
    const companyLogo = adData.company["company/logo"];
    const videoURL = adData["ad/video"];
    const companyName = adData.company["company/name"];
    const adObject = {
      watchedFlag,
      sharedFlag,
      _id,
      bonusQuestion,
      bonusQuestionAnswerChoices,
      questionPoints,
      videoPoints,
      postPoints,
      question,
      budgetRemaining,
      answerChoices,
      correctAnswer,
      coverImage,
      cashtag,
      tagline,
      companyLogo,
      videoURL,
      companyName,
      failedFlag,
      unansweredQuestions,
    };
    let earnableCoins = 0;
    earnableCoins += watchedFlag ? 0 : videoPoints;
    earnableCoins += sharedFlag ? 0 : postPoints;
    earnableCoins +=
      unansweredQuestions && unansweredQuestions.length > 0
        ? unansweredQuestions.reduce((acc, ques) => ques["question/answerPoints"] + acc, 0)
        : 0;
    earnableCoins = failedFlag ? 0 : earnableCoins;
    // const earnableCoins = (watchedFlag ? 0 : videoPoints + questionPoints + (bonusQuestionPoints || 0)) + (sharedFlag ? 0 : postPoints)
    const coinColor = earnableCoins ? "#EF4137" : "grey";
    this.setState({
      coinColor,
      earnableCoins,
      adObject,
      userAdId,
      watchedFlag,
      sharedFlag,
      failedFlag,
    });
  }

  componentDidUpdate() {
    if (
      this.state.updatingPost &&
      this.state.updatingPost.userAd &&
      Number(this.state.updatingPost.adId) === this.state.adObject._id
    ) {
      let userAdId = this.state.updatingPost.userAd;
      if (this.state.userAdId !== userAdId) {
        this.setState((prevState) => ({ ...prevState, userAdId }));
      }
    }
  }

  render() {
    let { coinColor, earnableCoins, adObject, userAdId, watchedFlag, sharedFlag, failedFlag } =
      this.state;
    adObject = adObject ? adObject : {};
    const { companyLogo, companyName, cashtag, tagline, _id, postPoints, coverImage } = adObject;
    const { innerWidth, innerHeight, toggleNav, vh, vw, forwardedRef } = this.props;
    const lineHeight = (size) => String(1.3 * size) + "px";
    let fs = Font.big;
    fs = innerWidth < 768 ? Font.med : fs;
    fs = innerWidth < 350 ? Font.small : fs;
    if (innerWidth < 1025) {
      return (
        <Paper
          style={{
            // display: '-webkit-box',
            // display: '-moz-box',
            // display: '-ms-flexbox',
            // display: '-webkit-flex',
            display: "flex", // position: 'relative',
            minHeight: 325,
            height: "100%",
            width: "100%",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "stretch",
            // padding: vw(3),
            marginBottom: vh(3),
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            // borderRadius: vw(7),
            opacity: 1,
          }}
        >
          {/* {(_id === 123 || _id === 456) && <div style={{
                            zIndex: 90, position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', opacity: 0.7, backgroundColor: '#CCC', borderRadius: vw(7),
                            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'
                        }}>
                            <img style={{ height: 61, width: 61 }} src={lockImg} />
                        </div>} */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              // padding: 'auto 2%',
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "-webkit-box",
                display: "-moz-box",
                display: "-ms-flexbox",
                display: "-webkit-flex",
                display: "flex", // position: 'relative',
                flexDirection: "row",
                justifyContent: "flex-start",
                marginLeft: "5%",
                width: "80%",
                marginTop: 11,
              }}
            >
              <div
                style={{
                  display: "-webkit-box",
                  display: "-moz-box",
                  display: "-ms-flexbox",
                  display: "-webkit-flex",
                  display: "flex", // position: 'relative',
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "transparent",
                  // borderRadius: vw(5.5),
                  marginRight: "5%",
                  height: vw(11),
                  width: vw(11),
                  overflow: "hidden",
                }}
              >
                <img
                  style={{ objectFit: "scale-down", width: vw(10) }}
                  src={`https://s3.amazonaws.com/${s3Bucket}/${companyLogo}`}
                />
              </div>
              <div
                style={{
                  display: "-webkit-box",
                  display: "-moz-box",
                  display: "-ms-flexbox",
                  display: "-webkit-flex",
                  display: "flex", // position: 'relative',
                  flexDirection: "column",
                  justifyContent: "flex-end",
                }}
              >
                <p
                  style={{
                    font: "normal normal bold 20px/24px Futura",
                    lineHeight: lineHeight(fs.subtext),
                    color: "#009444",
                    letterSpacing: 0,
                  }}
                >
                  Earn {earnableCoins} CA$H
                </p>
                <p
                  style={{
                    font: "normal normal normal 14px/20px Open Sans",
                    // lineHeight: lineHeight(fs.p1),
                    color: "#282560",
                    letterSpacing: 0,
                    // fontStyle: 'italic'
                  }}
                >
                  {companyName}
                </p>
              </div>
            </div>
            <div
              style={{
                display: "-webkit-box",
                display: "-moz-box",
                display: "-ms-flexbox",
                display: "-webkit-flex",
                display: "flex", // position: 'relative',
                flexDirection: "column",
                justifyContent: "center",
                marginRight: "5%",
              }}
            >
              <a
                onClick={(e) => this.handleTweet(e)}
                id={_id}
                className="twitter-container-b"
                href={
                  "https://twitter.com/intent/tweet?text=" +
                  encodeURIComponent(tagline) +
                  "&url=" +
                  encodeURIComponent("https://wearefabric.io") +
                  `&hashtags=${/^\$/.test(cashtag) ? cashtag.slice(1) : cashtag}`
                }
                data-size="large"
                data-text="Check this out!"
                data-url="https://wearefabric.io"
                data-hashtags={cashtag}
                data-postpoints={sharedFlag ? 0 : postPoints}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "-webkit-box",
                    display: "-moz-box",
                    display: "-ms-flexbox",
                    display: "-webkit-flex",
                    display: "flex", // position: 'relative',
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    // background: '#EF4137 0% 0% no-repeat padding-box',
                    // boxShadow: '0px 3px 6px #00000029',
                    // borderRadius: vw(2.5),
                    // width: vw(5),
                    // height: vw(5),
                    width: 20,
                    height: 20,
                  }}
                >
                  <ShareIcon style={{ color: "#3C97D2" }} />
                </div>
                {/* <p
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 0.8 * fs.p1,
                    lineHeight: lineHeight(0.8 * fs.p1),
                    fontWeight: 'bold',
                    letterSpacing: 0,
                    color: '#EF4137',
                    textAlign: 'center'
                  }}
                >
                  Share
                </p> */}
                {/* <Button><i className="fab fa-twitter" style={{ color: "White" }} />&nbsp;Share on Twitter</Button> */}
              </a>
            </div>
          </div>
          {/* {_id === 123 || _id === 456 ? <div style={{ height: vw(49), width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: vw(2), marginBottom: vw(2), overflow: 'hidden' }}><img style={{ height: '100%', width: '100%' }} src={`https://s3.amazonaws.com/${s3Bucket}/${coverImage}`} /></div> : <AdVideo ad={adObject} user={this.props.user} refreshUser={this.props.refreshUser} userAdId={userAdId} {...this.props} watched={watchedFlag} shared={sharedFlag} innerWidth={innerWidth} />} */}
          <AdVideo
            {...this.props}
            ad={adObject}
            user={this.props.user}
            refreshUser={this.props.refreshUser}
            userAdId={userAdId}
            watched={watchedFlag}
            shared={sharedFlag}
            failed={failedFlag}
            innerWidth={innerWidth}
            adjustEarnableCoins={this.adjustEarnableCoins.bind(this)}
          />
          {/* <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }}
          > */}
          <p
            style={{
              flex: 1,
              font: "normal normal normal 14px/20px Roboto",
              color: "#282560",
              letterSpacing: 0,
              textAlign: "left",
              // display: 'flex-item',
              width: "95%",
              margin: "10px auto",
              // marginLeft: '5%',
              // marginTop: 30,
              // marginRight: vw(1)
            }}
          >
            {tagline}
          </p>
          {/* <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                paddingTop: vw(3)
              }}
            >
              <img style={{ width: vw(5) }} src={coinsImg} />
              <p
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: 0.8 * fs.p1,
                  lineHeight: lineHeight(0.8 * fs.p1),
                  fontWeight: 'bold',
                  letterSpacing: 0,
                  color: coinColor,
                  opacity: 1
                }}
              >
                {earnableCoins > 0 ? earnableCoins : 0} CA$H
              </p>
            </div> */}
          {/* <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                marginLeft: vw(1.5),
                paddingTop: vw(3)
              }}
            >
              
            </div> */}
          {/* </div> */}
        </Paper>
      );
    }

    // console.log('request state', this.state);
    return (
      <Paper
        style={{
          display: "-webkit-box",
          display: "-moz-box",
          display: "-ms-flexbox",
          display: "-webkit-flex",
          display: "flex",
          // position: 'relative',
          flexDirection: "column",
          justifyContent: "flex-start",
          // minHeight: 743.5,
          width: 344,
          minHeight: 382,
          // height: '100%',
          alignItems: "stretch",
          // padding: '32px 45px',
          marginBottom: 60,
          marginRight: "1.6%",
          // marginTop: 75,
          background: "#fafafa 0% 0% no-repeat padding-box",
          // borderRadius: 32,
          opacity: 1,
          position: "relative",
          borderRadius: 4,
        }}
      >
        {/* {(_id === 123 || _id === 456) && <div style={{
                    zIndex: 90, position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', opacity: 0.7, backgroundColor: '#CCC', borderRadius: 32,
                    display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'
                }}>
                    <img style={{ height: 61, width: 61 }} src={lockImg} />
                </div>} */}
        <div
          style={{
            display: "-webkit-box",
            display: "-moz-box",
            display: "-ms-flexbox",
            display: "-webkit-flex",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 15,
            // marginBottom: 10
          }}
        >
          <div
            style={{
              display: "-webkit-box",
              display: "-moz-box",
              display: "-ms-flexbox",
              display: "-webkit-flex",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "80%",
            }}
          >
            <div
              style={{
                display: "-webkit-box",
                display: "-moz-box",
                display: "-ms-flexbox",
                display: "-webkit-flex",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "transparent",
                // borderRadius: 50,
                marginLeft: "5%",
                // marginRight: '5%',
                height: 40,
                width: 40,
                overflow: "hidden",
              }}
            >
              <img
                style={{ objectFit: "scale-down", width: 40 }}
                src={`${assetBaseURL}/${companyLogo}`}
              />
            </div>
            <div
              style={{
                display: "-webkit-box",
                display: "-moz-box",
                display: "-ms-flexbox",
                display: "-webkit-flex",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                marginLeft: "5%",
              }}
            >
              <p
                style={{
                  font: "normal normal bold 20px/24px Futura",
                  color: "#009444",
                  letterSpacing: 0,
                }}
              >
                Earn {earnableCoins} CA$H
              </p>
              <p
                style={{
                  font: "normal normal normal 14px/20px Open Sans",
                  color: "#282560",
                  letterSpacing: 0.25,
                }}
              >
                {companyName}
              </p>
            </div>
          </div>
          <div
            style={{
              display: "-webkit-box",
              display: "-moz-box",
              display: "-ms-flexbox",
              display: "-webkit-flex",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "20%",
            }}
          >
            <a
              onClick={(e) => this.handleTweet(e)}
              id={_id}
              className="twitter-container-b"
              href={
                "https://twitter.com/intent/tweet?text=" +
                encodeURIComponent(tagline) +
                "&url=" +
                encodeURIComponent("https://wearefabric.io") +
                `&hashtags=${/^\$/.test(cashtag) ? cashtag.slice(1) : cashtag}`
              }
              data-size="large"
              data-text="Check this out!"
              data-url="https://wearefabric.io"
              data-hashtags={cashtag}
              data-postpoints={sharedFlag ? 0 : postPoints}
            >
              <div
                style={{
                  display: "-webkit-box",
                  display: "-moz-box",
                  display: "-ms-flexbox",
                  display: "-webkit-flex",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 20,
                  height: 20,
                }}
              >
                <ShareIcon style={{ width: 20, height: 20, color: "#3C97D2" }} />
              </div>

              {/* <Button><i className="fab fa-twitter" style={{ color: "White" }} />&nbsp;Share on Twitter</Button> */}
            </a>
          </div>
        </div>
        {/* {_id === 123 || _id === 456 ? <div style={{ height: 460, width: 840, display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 25, marginBottom: 25, overflow: 'hidden' }}><img style={{ height: '460', width: '100%' }} src={`https://s3.amazonaws.com/${s3Bucket}/${coverImage}`} /></div> : <AdVideo ad={adObject} user={this.props.user} refreshUser={this.props.refreshUser} userAdId={userAdId} {...this.props} watched={watchedFlag} shared={sharedFlag} />} */}
        {/* {this.state.open && ( */}
        <Modal
          open={this.state.open}
          onClose={this.handleClose}
          style={{ position: "absolute", top: "50%", left: "50%" }}
          keepMounted
          // disablePortal
        >
          <div style={this.state.modalStyle()}>
            <AdVideo
              {...this.props}
              ad={adObject}
              user={this.props.user}
              refreshUser={this.props.refreshUser}
              userAdId={userAdId}
              watched={watchedFlag}
              shared={sharedFlag}
              failed={failedFlag}
              innerWidth={innerWidth}
              adjustEarnableCoins={this.adjustEarnableCoins.bind(this)}
              ref={forwardedRef}
            />
            <IconButton
              onClick={this.handleClose}
              style={{ position: "absolute", top: 10, right: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </Modal>
        {/* )} */}
        {this.state.adObject && (
          <img
            src={`https://s3.amazonaws.com/${s3Bucket}/` + this.state.adObject.coverImage}
            alt="still from advertisement"
            style={{
              objectFit: "contain",
              maxHeight: 200,
              cursor: "pointer",
            }}
            onClick={this.handleOpen}
          />
        )}
        <div
          style={{
            display: "-webkit-box",
            display: "-moz-box",
            display: "-ms-flexbox",
            display: "-webkit-flex",
            display: "flex",
            flexDirection: "row",
            width: "90%",
            alignSelf: "center",
            marginTop: 5,
          }}
        >
          <p
            style={{
              flex: 1,
              font: "normal normal normal 14px/20px Roboto",
              color: "#282560",
              letterSpacing: 0,
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            {tagline}
          </p>
          {/* <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'center',
              marginTop: 10
            }}
          >
            <img style={{ width: 35, height: 35 }} src={coinsImg} />
            <p
              style={{
                fontFamily: 'Open Sans',
                fontSize: 15,
                lineHeight: '20px',
                letterSpacing: 0,
                color: coinColor,
                opacity: 1
              }}
            >
              {earnableCoins > 0 ? earnableCoins : 0} CA$H
            </p>
          </div> */}
        </div>
        <Button
          variant={"text"}
          style={{
            width: "28%",
            backgroundColor: "transparent",
            position: "absolute",
            bottom: 17,
            left: 5,
            // padding: 0
          }}
          onClick={this.handleOpen}
        >
          <p
            style={{
              font: "normal normal medium 14px/16px Roboto",
              color: "#3C97D2",
            }}
          >
            WATCH AD
          </p>
        </Button>
      </Paper>
    );
  }
}

class Request2 extends Component {
  state = {};

  adjustEarnableCoins = (subtractor) => {
    this.setState((prevState) => ({
      ...prevState,
      earnableCoins:
        prevState.earnableCoins - (subtractor === undefined ? prevState.earnableCoins : subtractor),
    }));
  };

  handleTweet = (e) => {
    // window.twttr.events.unbind('tweet')
    // const iframe = document.querySelector('iframe')
    // iframe && iframe.remove()
    if (e.target.dataset.postpoints === "0") {
      return;
    } else {
      this.addPoints(Number(e.currentTarget.dataset.postpoints), e.currentTarget.id, "Twitter")
        .then((res) => {
          this.setState({ sharedOnTwitter: true });
        })
        .catch((err) => {
          return;
        });
    }
  };

  addPoints = (points, adId, socialMedia) => {
    if (this.state.addingPoints > 0) {
      return new Promise((res, rej) => res("success"));
    }
    this.setState((prevState) => {
      return {
        ...prevState,
        addingPoints: prevState.addingPoints ? prevState.addingPoints + 1 : 1,
        updatingPost: { adId },
      };
    });
    const userId = this.props.user._id;
    const walletId = get(this.props.user, ["user/wallet", "_id"]);
    const balance = get(this.props.user, ["user/wallet", "wallet/balance"]);
    const earnings = get(this.props.user, ["user/wallet", "wallet/earnings"]);
    const state = this.state;
    const adData = this.props.ad;
    const budgetRemaining = adData["ad/budgetRemaining"];
    // if (!this.props.user['user/userAds']) {
    //     throw new Error("Loop again and return")
    // }
    let userAdId;

    if (!!this.props.user["user/userAds"]) {
      userAdId = this.props.user["user/userAds"].find((ad) => {
        return ad["userAd/ad"]["_id"] === parseInt(adId);
      });
      userAdId = userAdId ? userAdId["_id"] : null;
    }

    let transaction = [
      {
        _id: Number(walletId),
        balance: `#(+ ${balance} ${points})`,
        earnings: `#(+ ${earnings} ${points})`,
      },
    ];

    let tx2;
    let tx3;
    if (userAdId) {
      tx2 = {
        _id: Number(userAdId),
        sharedOnTwitter: true,
      };
    } else {
      tx2 = {
        _id: userId,
        userAds: ["userAd$new"],
      };
      tx3 = {
        _id: "userAd$new",
        sharedOnTwitter: true,
        ad: Number(adId),
      };
    }

    // tx2[`sharedOn${socialMedia}`] = true;

    const tx4 = {
      _id: Number(adId),
      budgetRemaining: `#(- ${budgetRemaining} ${points})`,
    };

    transaction.push(tx2);
    if (tx3) {
      transaction.push(tx3);
    }
    transaction.push(tx4);
    const authToken = getToken();

    return flureeFetch("transact", transaction, authToken)
      .then((res) => {
        // let newState = {}
        // newState[`sharedOn${socialMedia}`] = true;
        // this.setState(newState);
        // debugger;
        if (res.tempids && res.tempids["userAd$new"]) {
          this.setState((prevState) => {
            return {
              ...prevState,
              updatingPost: {
                ...prevState.updatingPost,
                userAd: res.tempids["userAd$new"],
              },
            };
          });
        }
        this.setState({ addingPoints: null });
        this.adjustEarnableCoins(points);
        return;
      })
      .then((res) => {
        // debugger;
        this.props.refreshUser();
      })
      .catch((err) => {
        // debugger;
        this.setState({ error: err });
      });
  };

  componentDidMount() {
    const { ad } = this.props;
    const userAd = ad.userAd;
    let failedFlag, sharedFlag, watchedFlag, questionsSucceeded, consumerData, answeredQuestions;
    if (userAd) {
      sharedFlag = userAd["userAd/sharedOnTwitter"];
      watchedFlag = userAd["userAd/watched"];
      failedFlag = userAd.questionFailed;
      questionsSucceeded =
        userAd.questionsSucceeded &&
        userAd.questionsSucceeded.map((ans) => ans["answer/question"]._id);
      consumerData =
        userAd.consumerData && userAd.consumerData.map((ans) => ans["answer/question"]._id);
      answeredQuestions =
        (questionsSucceeded && consumerData && questionsSucceeded.concat(consumerData)) ||
        questionsSucceeded ||
        consumerData;
    }
    const unansweredQuestions = ad.questions.filter(
      (ques) => !(answeredQuestions && answeredQuestions.includes(ques._id))
    );
    // const answeredQuestions = userAd && (userAd['userAd/questionsSucceeded'] && userAd['userAd/consumerData'] && userAd['userAd/questionsSucceeded'].concat(userAd['userAd/consumerData']) || userAd['userAd/questionsSucceeded'] || userAd['userAd/consumerData'])
    // const answeredQuestionIds = answeredQuestions && answeredQuestions.map(ans => ans._id)

    // const unansweredQuestions = ad.questions.filter(question => !(answeredQuestionIds && answeredQuestionIds.includes(question._id)))
    const adData = ad;
    const _id = ad._id;
    let userAdId = userAd && userAd._id;
    if (
      this.state.updatingPost &&
      this.state.updatingPost.userAd &&
      Number(this.state.updatingPost.adId) === _id
    ) {
      userAdId = this.state.updatingPost.userAd;
    }
    //can eventually delete from here...
    const question = adData["ad/question"];
    const questionPoints = adData["ad/questionPoints"];
    const answerChoices = adData["ad/answerChoices"];
    const correctAnswer = adData["ad/correctAnswer"];
    const bonusQuestion = adData["ad/bonusQuestion"];
    const bonusQuestionPoints = adData["ad/bonusQuestionPoints"];
    const bonusQuestionAnswerChoices = adData["ad/bonusQuestionAnswerChoices"];
    //..to here
    const videoPoints = adData["ad/videoPoints"];
    const postPoints = adData["ad/postPoints"];
    const budgetRemaining = adData["ad/budgetRemaining"];
    const coverImage = adData["ad/coverImage"];
    const cashtag = adData["ad/cashtag"];
    const tagline = adData["ad/tagline"];
    const companyLogo = adData.company["company/logo"];
    const videoURL = adData["ad/video"];
    const companyName = adData.company["company/name"];
    const adObject = {
      watchedFlag,
      sharedFlag,
      _id,
      bonusQuestion,
      bonusQuestionAnswerChoices,
      questionPoints,
      videoPoints,
      postPoints,
      question,
      budgetRemaining,
      answerChoices,
      correctAnswer,
      coverImage,
      cashtag,
      tagline,
      companyLogo,
      videoURL,
      companyName,
      failedFlag,
      unansweredQuestions,
    };
    let earnableCoins = 0;
    earnableCoins += watchedFlag ? 0 : videoPoints;
    earnableCoins += sharedFlag ? 0 : postPoints;
    earnableCoins +=
      unansweredQuestions && unansweredQuestions.length > 0
        ? unansweredQuestions.reduce((acc, ques) => ques["question/answerPoints"] + acc, 0)
        : 0;
    earnableCoins = failedFlag ? 0 : earnableCoins;
    // const earnableCoins = (watchedFlag ? 0 : videoPoints + questionPoints + (bonusQuestionPoints || 0)) + (sharedFlag ? 0 : postPoints)
    const coinColor = earnableCoins ? "#EF4137" : "grey";
    this.setState({
      coinColor,
      earnableCoins,
      adObject,
      userAdId,
      watchedFlag,
      sharedFlag,
      failedFlag,
    });
  }

  componentDidUpdate() {
    if (
      this.state.updatingPost &&
      this.state.updatingPost.userAd &&
      Number(this.state.updatingPost.adId) === this.state.adObject._id
    ) {
      let userAdId = this.state.updatingPost.userAd;
      if (this.state.userAdId !== userAdId) {
        this.setState((prevState) => ({ ...prevState, userAdId }));
      }
    }
  }

  render() {
    const { classes } = this.props;
    let { coinColor, earnableCoins, adObject, userAdId, watchedFlag, sharedFlag, failedFlag } =
      this.state;
    adObject = adObject ? adObject : {};
    const { companyLogo, companyName, cashtag, tagline, _id, postPoints, coverImage } = adObject;
    return <Grid item xs={12} className={classes.root}></Grid>;
  }
}

const StyledRequest = withStyles(useStyles, { withTheme: true })(Request2);

class Requests extends Component {
  state = {
    userAds: [],
    watchedAds: [],
    innerHeight:
      this.testLandscape() && this.testMobile()
        ? (812 / 375) * window.innerWidth
        : window.innerHeight,
    innerWidth: window.innerWidth,
    isLandscape: window.innerWidth > window.innerHeight,
    isMobile: window.innerWidth < 1025,
  };

  testLandscape() {
    return window.innerWidth > window.innerHeight;
  }

  testMobile() {
    return window.innerWidth < 1025;
  }

  vh = (num) => num * 0.01 * this.state.innerHeight;
  vw = (num) => num * 0.01 * this.state.innerWidth;

  componentDidMount() {
    // const profilePic = get(this.props.user, "user/profilePic");
    // const profilePicPath = profilePic.match(/^http/)
    //   ? profilePic
    //   : `https://s3.amazonaws.com/${s3Bucket}/${profilePic}`;
    this.setState({ dummyAds });
    this.getAdInfo();

    // new Promise((resolve, reject) => {
    //     window.twttr.widgets.load(document.getElementById('twitter-container-b'))
    //     resolve("Loaded")
    // })
    //     .then(res => {
    //         window.twttr.events.bind('tweet', (e) => this.handleTweet(e))
    //     })
  }

  // componentDidUpdate = () => {
  //     new Promise((resolve, reject) => {
  //         window.twttr.widgets.load(document.getElementById('twitter-container-b'))
  //         resolve("Loaded")
  //     })
  //         .then(res => {
  //             window.twttr.events.bind('tweet', (e) => this.handleTweet(e))
  //         })
  // }

  getAdInfo() {
    const handle = this.props.user["user/handle"];
    const authToken = getToken();

    queryAdsForUser(handle, authToken)
      .then(({ requestAds, watchedAds }) => this.setState({ userAds: requestAds, watchedAds }))
      .catch((err) => {
        debugger;
        this.setState({ error: err });
      });
  }

  render() {
    let count = this.state.userAds.length;
    const dummyAds = this.state.dummyAds;
    // debugger;
    const ads = this.state.userAds.concat(this.state.watchedAds); //.concat(this.state.dummyAds)
    // if (ads[0]) {
    //     debugger;
    // }
    const renderAds = (innerWidth) =>
      ads[0] &&
      ads.map((ad) => (
        <Request
          {...this.props}
          vw={this.vw.bind(this)}
          vh={this.vh.bind(this)}
          ad={ad}
          innerWidth={innerWidth}
        />
      ));
    // const watched = this.state.userAds.length === 0
    // const state = this.state
    // if (ad) {
    //     debugger
    // }
    const { toggleNav } = this.props;
    const { innerWidth, innerHeight } = this.state;
    const { vw, vh } = this;
    const lineHeight = (size) => String(1.3 * size) + "px";
    let fs = Font.big;
    fs = innerWidth < 768 ? Font.med : fs;
    fs = innerWidth < 350 ? Font.small : fs;
    const renderedAds = renderAds(innerWidth);
    if (innerWidth < 1025) {
      return (
        <div
          style={{
            display: "-webkit-box",
            display: "-moz-box",
            display: "-ms-flexbox",
            display: "-webkit-flex",
            display: "flex", // position: 'relative',
            // height: innerHeight,
            flexDirection: "column",
            backgroundColor: "#F4FBFF",
          }}
        >
          {/* This will become MobileTopNav component */}
          {/* <MobileTopNav {...this.props} toggleNav={toggleNav} profilePic={this.state.profilePic} balance={this.props.user['user/wallet']['wallet/balance']} vw={vw} /> */}
          <div
            style={{
              display: "-webkit-box",
              display: "-moz-box",
              display: "-ms-flexbox",
              display: "-webkit-flex",
              display: "flex", // position: 'relative',
              flex: 1,
              flexDirection: "column",
              alignItems: "stretch",
              justifyContent: "flex-start",
              maxWidth: 900,
              width: "100%",
              paddingTop: vw(10),
              paddingRight: vw(2),
              paddingLeft: vw(2),
              overflow: "scroll",
            }}
          >
            <p
              style={{
                fontFamily: "PT Sans",
                fontSize: fs.h1,
                lineHeight: lineHeight(fs.h1),
                letterSpacing: 0,
                color: "#4096D2",
                opacity: 1,
                marginBottom: vh(2),
              }}
            >
              Fabric CA$HFEED
            </p>
            <p
              style={{
                fontFamily: "PT Sans",
                fontSize: fs.p1,
                lineHeight: lineHeight(fs.p1),
                letterSpacing: 0,
                color: "#282560",
                opacity: 1,
                textAlign: "center",
                marginBottom: vh(4),
                paddingLeft: vw(10),
                paddingRight: vw(10),
              }}
            >
              Watch ads and answer questions to earn Social Cash that you can redeem through your
              Fabric Card!{" "}
            </p>
            {renderedAds}
          </div>
        </div>
      );
    }

    return (
      <>
        {/* main dashboard content */}
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            height: innerHeight - 100,
            padding: "0 24px 0 24px",
            overflow: "scroll",
            backgroundColor: "#EEF9FE",
          }}
        >
          <p
            style={{
              font: "normal normal bold 24px/32px Futura",
              letterSpacing: 0,
              color: "#282560",
              opacity: 1,
              alignSelf: "flex-start",
            }}
          >
            Fabric CA$HFEED
          </p>
          <p
            style={{
              font: "normal normal normal 14px/19px Open Sans",
              letterSpacing: 0,
              color: "#282560",
              opacity: 1,
              textAlign: "center",
              marginTop: 0,
              alignSelf: "flex-start",
              marginBottom: 26,
            }}
          >
            Watch ads and answer questions to earn Social Cash that you can redeem through your
            Fabric Card!
          </p>
          <div
            style={{
              display: "flex",
              alignSelf: "stretch",
              flexWrap: "wrap",
              alignContent: "flex-start",
            }}
          >
            {renderedAds}
          </div>
        </div>

        {/* small nav buttons */}
        <div style={{ position: "absolute", top: 20, right: 20, zIndex: 100 }}>
          <NavIcons user={this.props.user} imgSrc={this.state.profilePic} />
        </div>
      </>
    );
  }
}

const Requests2 = (props) => {
  const classes = useStyles();
  const [userAds, setUserAds] = useState([]);
  const [watchedAds, setWatchedAds] = useState([]);
  const [profilePic, setProfilePic] = useState(null);
  const [dummyAds, setDummyAds] = useState(null);
  const [error, setError] = useState(null);
  const [ads, setAds] = useState([]);

  useEffect(() => {
    const profilePic = get(props.user, "user/profilePic");
    const profilePicPath = profilePic.match(/^http/)
      ? profilePic
      : `https://s3.amazonaws.com/${s3Bucket}/${profilePic}`;
    setProfilePic(profilePicPath);
    getAdInfo();
  }, []);

  useEffect(() => {
    setAds(userAds.concat(watchedAds));
  }, [userAds, watchedAds]);

  const renderAds = () => ads[0] && ads.map((ad) => <Request {...props} ad={ad} />);

  const getAdInfo = () => {
    const handle = props.user["user/handle"];
    const authToken = getToken();

    flureeFetch(
      "multi-query",
      {
        // "userAds": {
        //     "select": ["?watched", "?shared", "?ad", { "?ad": ["*", { "company": ["*"] }] }],
        //     "where": [["?user", "user/handle", handle],
        //     ["?user", "user/shoppingInterests", "?interest"],
        //     ["?ad", "ad/categories", "?interest"],
        //     ["?ad", "ad/finished", "?finished", { "filter": "(and ?finished)" }],
        //     ["?user", "user/userAds", "?userAd"],
        //     ["?userAd", "userAd/ad", "?ad"],
        //     ["?userAd", "userAd/watched", "?watched",  {"optional": true}],
        //     ["?userAd", "userAd/sharedOnTwitter", "?shared", { "optional": true }]],
        //     "orderBy": ["ASC", "?ad"]
        // },

        userAds: {
          select: {
            "?userAd": [
              "*",
              { questionsSucceeded: ["*"] },
              { questionFailed: ["*"] },
              { consumerData: ["*"] },
            ],
          },
          where: [
            ["?user", "user/handle", handle],
            ["?user", "user/userAds", "?userAd"],
          ],
        },
        allAds: {
          select: { "?ad": ["*", { company: ["*"] }, { questions: ["*"] }] },
          where: [
            ["?user", "user/handle", handle],
            ["?user", "user/shoppingInterests", "?interest"],
            ["?ad", "ad/categories", "?interest"],
            ["?ad", "ad/finished", "?finished"],
          ],
          filter: ["(and ?finished)"],
        },

        // "select": ["?ad", { "?ad": ["*", { "company": ["*"] }] }, "?userAd"], //"?questionFailed", "?questionsSucceeded", "?watched", "?shared",
        // "where": [["?user", "user/handle", handle],
        // ["?user", "user/shoppingInterests", "?interest"],
        // ["?ad", "ad/categories", "?interest"],
        // ["?ad", "ad/finished", "?finished", { "filter": "(and ?finished)" }],
        // ["?user", "user/userAds", "?userAd", { "optional": true }],
        // ["?userAd", "userAd/ad", "?ad", { "optional": true }]
        // ["?userAd", "userAd/watched", "?watched", { "optional": true }],
        // ["?userAd", "userAd/questionFailed", "?questionFailed", { "optional": true }],
        // ["?userAd", "userAd/questionsSucceeded", "?questionsSucceeded", { "optional": true }],
        // ["?userAd", "userAd/sharedOnTwitter", "?shared", { "optional": true }]]
        // "allAds": {
        //     "select": ["?ad", { "?ad": ["*", { "company": ["*"] }] }],
        //     "where": [["?user", "user/handle", handle],
        //     ["?user", "user/shoppingInterests", "?interest"],
        //     ["?ad", "ad/categories", "?interest"],
        //     ["?ad", "ad/finished", "?finished", { "filter": "(and ?finished)" }]]
        // }
      },
      authToken
    )
      .then((res) => {
        // debugger;
        let requestAds = [];
        let watchedAds = [];
        let completedAds = [];

        // let userAds = get(res, "userAds");
        // let allAds = get(res, "allAds");
        const { allAds, userAds } = res;
        // const adIdObj = {}
        // allAds = allAds.filter(ad => {
        //     if(adIdObj[ad[2]]) {
        //         return false
        //     } else {
        //         adIdObj[ad[2]] = true
        //         return true
        //     }
        // })
        // debugger;
        // userAds.map(userAds => {
        //     let watched = userAds[0];
        //     if (watched) {
        //         watchedAds.push(userAds[1])
        //     } else {
        //         requestAds.push(userAds[2])
        //     }
        // })
        allAds.map((ad) => {
          const userAd = userAds[0] && userAds.find((userAd) => userAd["userAd/ad"]._id === ad._id);
          if (userAd) {
            ad["userAd"] = userAd;
            watchedAds.push(ad);
          } else {
            requestAds.push(ad);
          }
        });
        // requestAds.push(allAds[0][1]) for now, allow users to repeatedly access Fabric ad

        // Order by id
        requestAds = requestAds.sort(function (a, b) {
          let aId = get(a, "ad/id");
          let bId = get(b, "ad/id");
          return aId - bId;
        });
        watchedAds = watchedAds.sort(function (a, b) {
          let aId = get(a, "ad/id");
          let bId = get(b, "ad/id");
          return aId - bId;
        });

        // let totalPotentialPoints = requestAds.reduce((acc, value) => {
        //     let questionPoints = get(value[3], "ad/questionPoints");
        //     let videoPoints = get(value[3], "ad/videoPoints");
        //     let postPoints = get(value[3], "ad/postPoints") || 0
        //     return acc + questionPoints + videoPoints + postPoints
        // }, 0);
        // this.setState({ userAds: requestAds, watchedAds });
        setUserAds(requestAds);
        setWatchedAds(watchedAds);
      })
      .catch((err) => {
        debugger;
        // this.setState({ error: err })
        setError(err);
      });
  };

  return (
    <>
      {/* main dashboard content */}
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          height: "100%",
          padding: "50px 50px 0 50px",
          overflow: "scroll",
        }}
      >
        <p
          style={{
            fontFamily: "PT Sans",
            fontSize: 60,
            lineHeight: "78px",
            letterSpacing: 0,
            color: "#4096D2",
            opacity: 1,
          }}
        >
          Fabric CA$HFEED
        </p>
        <p
          style={{
            fontFamily: "Open Sans",
            fontSize: 25,
            lineHeight: "43px",
            letterSpacing: 0,
            color: "#282560",
            opacity: 1,
            textAlign: "center",
            marginTop: 25,
          }}
        >
          Watch ads and answer questions to earn Social Cash that you can redeem through your Fabric
          Card!
        </p>
        {ads.map((ad) => (
          <Request {...props} ad={ad} />
        ))}
      </div>

      {/* small nav buttons */}
      <div style={{ position: "absolute", top: 20, right: 20, zIndex: 100 }}>
        <NavIcons user={props.user} imgSrc={profilePic} />
      </div>
    </>
  );
};

export default Requests;

/*

[
     [ 387028092977203, 1019, 369435906932737, -1142, false, null ],
     [ 387028092977202, 1019, 369435906932737, -1142, false, null ],
     [ 387028092977201, 1019, 369435906932737, -1142, false, null ],
     [ 387028092977186, 1019, 369435906932737, -1142, false, null ],
     [ 387028092977185, 1019, 369435906932737, -1142, false, null ],
     [ 387028092977172, 1019, 369435906932737, -1142, false, null ],
     [ 387028092977171, 1019, 369435906932737, -1142, false, null ],
     [ 387028092977170, 1019, 369435906932737, -1142, false, null ],
     [ 387028092977169, 1019, 369435906932737, -1142, false, null ],
     [ 387028092977168, 1019, 369435906932737, -1142, false, null ],
     [ 387028092977167, 1019, 369435906932737, -1142, false, null ],
     [ 387028092977166, 1019, 369435906932737, -1142, false, null ],
     [ 387028092977161, 1019, 369435906932737, -1142, false, null ],
     [ 369435906932737, 1033, "Fabric Ad", -1142, false, null ],
     [ 369435906932737, 1034, "initialData/ads/fabric.mp4", -1142, false, null ],
     [ 369435906932737, 1035, "initialData/ads/fabricAdCoverImage.png", -1142, false, null ],
     [ 369435906932737, 1036, "initialData/ads/fabricAdThumbnail.png", -1142, false, null ],
     [ 369435906932737, 1037, 1, -1142, false, null ],
     [ 369435906932737, 1038, 404620279021569, -1142, false, null ],
     [ 369435906932737, 1040, "On a scale from 1 to 5, how relevant is this ad to your consumer experience?", -1142, false, null ],
     [ 369435906932737, 1041, "1", -1142, false, null ],
     [ 369435906932737, 1041, "2", -1142, false, null ],
     [ 369435906932737, 1041, "3", -1142, false, null ],
     [ 369435906932737, 1041, "4", -1142, false, null ],
     [ 369435906932737, 1041, "5", -1142, false, null ],
     [ 369435906932737, 1042, "5", -1142, false, null ],
     [ 369435906932737, 1043, 200, -1142, false, null ],
     [ 369435906932737, 1044, 50, -1142, false, null ],
     [ 369435906932737, 1045, "On a scale from 1 to 5, how interested are you in continuing to participate with this brand?", -1142, false, null ],
     [ 369435906932737, 1046, 50, -1142, false, null ],
     [ 369435906932737, 1047, "1", -1142, false, null ],
     [ 369435906932737, 1047, "2", -1142, false, null ],
     [ 369435906932737, 1047, "3", -1142, false, null ],
     [ 369435906932737, 1047, "4", -1142, false, null ],
     [ 369435906932737, 1047, "5", -1142, false, null ],
     [ 369435906932737, 1048, "5", -1142, false, null ],
     [ 369435906932737, 1049, 474989023199263, -1142, false, null ],
     [ 369435906932737, 1050, "Collectively, we can create an advertising landscape that benefits the consumers through fair compensation and transparency. Our data. Our terms.", -1142, false, null ],
     [ 369435906932737, 1051, 52776558134248, -1142, false, null ],
     [ 369435906932737, 1052, 10000, -1142, false, null ],
     [ 369435906932737, 1053, 998150, -1142, false, null ],
     [ 369435906932737, 1054, true, -1142, false, null ],
     [ 369435906932737, 1079, 100, -1142, false, null ],
     [ -1142, 100, "eb5e1087f1f0efab9d1603946c50620547ca0444a1066a195b4b0009c53b0dfc", -1142, true, null ],
     [ -1142, 101, 105553116266496, -1142, true, null ],
     [ -1142, 103, 1569505747146, -1142, true, null ],
     [ -1142, 106, "{\"type\":\"tx\",\"db\":\"fabric/mvp4\",\"tx\":[{\"_id\":369435906932737,\"_action\":\"delete\"}],\"nonce\":1569505747146,\"auth\":\"Tf39wv7cz29KpXDRMxSgn3KGCWXB3PrKgw6\",\"expire\":1569505777148}",
      -1142,
      true,
      null
    ],
     [ -1142, 107, "1b3045022100928fe58f04ebe3a56244fdde2b88b2dba635fba8bafdf3ba2f04fc9be93f62570220342a7c5b3103769daca36d18db0783ae1f5669a149d6e2e1ee7195f10535e648", -1142, true, null ],
     [ -1142, 108, "{\"369435906932737\":369435906932737}", -1142, true, null ]
  ]

*/
