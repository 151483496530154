import React, { useState, useContext } from "react";
import { flureeFetch, getToken, parseJSON } from "../flureeFetch";
import { makeStyles } from "@material-ui/core/styles";
import { Link, withRouter } from "react-router-dom";
import get from "lodash.get";
import { serverURL } from "../appConfig";
import { Button, Form } from "react-bootstrap";
import UserContext from "../contexts/UserContext";
import Logo from "../assets/img/fabric_logo_hd.png";
import { Container, Hidden, IconButton, Typography } from "@material-ui/core";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import clsx from "clsx";
import { trackPromise } from "react-promise-tracker";

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: 1920,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "flex-end",
    height: "100vh",
    backgroundColor: "#F4FBFF",
    // minwidth: 1376,
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
      alignItems: "center",
    },
  },
  logo: {
    width: "50%",
    height: "100vh",
    background: `transparent url(${Logo}) center / contain no-repeat padding-box`,
    opacity: 1,
    backgroundColor: "#FFFFFF",
    backgroundSize: "60% auto",
    flexShrink: 0,
  },
  mobileLogo: {
    maxWidth: 75,
    height: 75,
    width: "100%",
    background: `transparent url(${Logo}) center / cover no-repeat padding-box`,
    position: "absolute",
    top: 0,
    left: 0,
  },
  inner: {
    width: "50%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-between",
    // alignItems: "stretch",
    // marginRight: 30,
    [theme.breakpoints.down("sm")]: {
      minWidth: "auto",
      width: "100%",
      height: "auto",
      marginRight: 0,
    },
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    color: "#4096D2",
    fontFamily: "PT Sans",
    fontSize: 60,
    height: 65,
    lineHeight: "78px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 32,
      lineHeight: "42px",
      marginBottom: 0,
      marginTop: 50,
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 533,
    // margin: '40px 45px',
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "0px 50px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0px 20px",
    },
  },
  label: {
    fontFamily: "Open Sans",
    fontSize: 20,
    color: theme.palette.text.primary, //"#282560",
    lineHeight: "27px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      lineHeight: "21px",
    },
  },
  control: {
    borderWidth: 3,
    borderColor: theme.palette.text.primary,
    height: 54,
    fontFamily: "PT Sans",
    fontSize: 20,
    fontStyle: "italic",
    backgroundColor: "transparent",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      borderWidth: 2,
      height: 40,
    },
  },
  button: {
    display: "block",
    width: 256,
    height: 54,
    fontFamily: "PT Sans",
    fontSize: 25,
    [theme.breakpoints.down("sm")]: {
      width: "43vw",
      height: 45,
      fontSize: 18,
      margin: 5,
    },
  },
  backButton: {
    position: "absolute",
    top: 5,
    left: "1%",
  },
}));

function Login(props) {
  const classes = useStyles();
  const [user, setUser] = useState({});
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const { refreshUser } = useContext(UserContext);

  const responseFacebook = (response) => {
    const { name, email, userID } = response;
    const firstName = name.substr(0, name.indexOf(" "));
    const lastName = name.substr(name.indexOf(" ") + 1);
    let imageUrl = "";

    let transaction = [
      {
        _id: "user$1",
        facebookId: userID,
        email: email,
        registered: false,
      },
    ];

    flureeFetch("/transact", transaction)
      .then((res) => {
        const _id = get(res, ["tempids", "user$1"]);
        setUser({
          _id: _id,
          firstName: firstName,
          lastName: lastName,
          imageUrl: imageUrl,
        });
        //props.changeStep(1);
      })
      .catch((err) =>
        setError({
          message: "Something went wrong trying to create your account: " + JSON.stringify(err),
        })
      );
  };

  const responseGoogle = (response) => {
    if (response.error) {
      setError({ message: response.error });
    } else {
      const { email } = response.profileObj;

      let query = {
        select: ["_id"],
        from: ["user/email", email],
      };
      flureeFetch("/query", query)
        .then((res) => {
          if (res) {
            const handle = res["_id"];
            localStorage.setItem("fabricUser", handle);
            refreshUser().then((res) => {
              props.history.push("/");
            });
          } else {
            setError({
              message: `There is not an account associated with this email. Did you mean to register?`,
            });
          }
        })
        .catch((err) => {
          setError({
            message:
              "Something went wrong trying to authenticate your account: " +
              JSON.stringify(err, null),
          });
        });
    }
  };

  const signIn = (e) => {
    e.preventDefault();
    //this.props.beginLoading("Authenticating User...")

    if (!email || !password) {
      setError({
        message: "You must provide an email and password.",
      });
    } else {
      const userData = {
        email: email.toLowerCase(),
        password,
      };
      debugger;
      trackPromise(
        // getToken()
        // .then((token) => {
        fetch(`${serverURL}authenticate`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            userData,
            // token
          }),
          // });
        })
          .then((res) => {
            debugger;
            return parseJSON(res);
          })
          .then((res) => {
            // console.log(res);
            debugger;
            //this.props.endLoading("Authenticating User...")
            // window.localStorage.setItem("fabricUser", res.json.id.toString());
            window.localStorage.setItem("stringBean", res.json.token);
            setError(null);
            refreshUser().then((res) => {
              debugger;
              props.history.push("/");
            });
          })
          .catch((err) => {
            debugger;
            //this.props.endLoading("Authenticating User...")
            setError({ message: err.message || err });
          })
      );
    }
  };

  return (
    <Container maxWidth={"xl"} disableGutters className={classes.root}>
      <Hidden mdDown>
        <div className={classes.logo}>
          <Link to="/">
            <IconButton className={classes.backButton}>
              <ArrowBackIos />
            </IconButton>
          </Link>
        </div>
      </Hidden>
      <Hidden lgUp>
        <Link to="/">
          <div className={classes.mobileLogo}></div>
        </Link>
      </Hidden>
      <div className={classes.inner}>
        <p className={classes.header}>SIGN IN</p>
        {error ? (
          <div
            className="text-center"
            style={{
              color: "red",
              margin: "20px 0px",
              fontSize: "14px",
              fontFamily: "Open Sans",
            }}
          >
            {error.message}
          </div>
        ) : (
          <div style={{ margin: "15px 0px" }}>
            {/* <span
              style={{
								display: "inline-block",
								textAlign: "center",
                color: "red",
                margin: "20px 0px",
								padding: "0px 20px",
								wordWrap: "normal",
                fontSize: "14px",
                fontFamily: "Open Sans",
              }}
            >
              Fabric is currently undergoing maintenance. We appreciate your patience as we make
              updates to improve your experience.
            </span> */}
          </div>
        )}
        <Form
          onSubmit={(e) => {
            signIn(e);
          }}
          className={classes.form}
        >
          <Form.Group controlId="email" style={{ width: "100%", marginBottom: 35 }}>
            <Form.Label className={classes.label}>Email Address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={classes.control}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId="password" style={{ width: "100%", marginBottom: 50 }}>
            <Form.Label className={classes.label}>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={classes.control}
            ></Form.Control>
          </Form.Group>
          {/* <Typography>or</Typography> */}
          <div className={classes.federatedWrapper}></div>
          <div className="text-center">
            <Button
              className={clsx(classes.button, "brand-btn", "landing-btn")}
              type="submit"
              disabled={!email || !password}
              // disabled
            >
              Sign In
            </Button>
          </div>
          <div className="text-center" style={{ marginTop: "5px" }}>
            <Link to="/reset" className="btn" style={{ color: "#2C3E50", fontFamliy: "Open Sans" }}>
              <small className="form-text brand-link">Forgot Your Password?</small>
            </Link>
          </div>
        </Form>
      </div>
    </Container>
  );
}

export default withRouter(Login);
