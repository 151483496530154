import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import { s3Bucket } from "../appConfig";
import get from "lodash.get";
import NavIcons from "./NavIcons";
import fabricLogo from "../assets/img/navSec/FabricLogo.png";
import FilterListIcon from "@material-ui/icons/FilterList";
import Hidden from "@material-ui/core/Hidden";
import UserContext from "../contexts/UserContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // background: theme.palette.primary.main
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      width: "100%;",
    },
    zIndex: theme.zIndex.drawer + 1,
    height: 100,
    [theme.breakpoints.down("sm")]: {
      height: 80,
    },
    background: theme.palette.primary.main,
  },
  toolBar: {
    padding: 0,
  },
  logo: {
    flex: "auto",
    maxWidth: 350,
    height: 100,
    width: "100%",
  },
  logoImage: {
    marginTop: 10,
    marginLeft: 10,
    height: 86,
    width: 133,
    opacity: 1,
  },
  navIcon: {
    width: "calc(100% - 250px)",
    padding: 10,
    height: "100%",
    background: theme.palette.primary.light,
    [theme.breakpoints.down("sm")]: {
      background: theme.palette.primary.main,
    },
  },
  navIconProfile: {
    width: "calc(100% - 250px)",
    padding: 10,
    height: "100%",
    background: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      background: theme.palette.primary.main,
    },
  },
  menuButton: {
    marginLeft: 20,
    //marginTop: 5,
    marginRight: "auto !important",
    color: theme.palette.text.primary,
  },
}));

function HeaderBar(props) {
  const classes = useStyles();
  const { user, handleMenuClick } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);
  // const profilePic = get(user, 'user/profilePic');
  // const profilePicPath = profilePic.match(/^http/)
  //   ? profilePic
  //   : `https://s3.amazonaws.com/${s3Bucket}/${profilePic}`;

  // const handleClick = event => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  // console.log({location: props.location.pathname})

  return (
    <AppBar position="relative" className={classes.appBar} color="inherit" elevation={0}>
      <Toolbar className={classes.toolBar}>
        <Hidden smDown>
          <div className={classes.logo}>
            <a href="/">
              <div
                className={classes.logoImage}
                style={{
                  background: `transparent url(${fabricLogo}) 0% 0% no-repeat padding-box`,
                }}
              ></div>
            </a>
          </div>
        </Hidden>
        <Hidden mdUp>
          <FilterListIcon
            color="primary"
            aria-label="open drawer"
            edge="start"
            className={classes.menuButton}
            onClick={handleMenuClick}
            fontSize="large"
          ></FilterListIcon>
        </Hidden>
        <div
          className={
            props.location.pathname == "/smart-profile" ? classes.navIconProfile : classes.navIcon
          }
        >
          <NavIcons imgSrc={"#"} />
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default withRouter(HeaderBar);
