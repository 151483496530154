import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Container, Hidden } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Background from "../assets/img/landingPage/Asset3.png";
import Logo from "../assets/img/fabric_logo_hd.png";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    // flexDirection: 'row',
    // justifyContent: 'space-between',
    // alignItems: 'center',
    height: "100vh",
    // maxWidth: 1920,
    width: "100%",
    // minwidth: 1376
    backgroundColor: "#F4FBFF",
  },
  // rootLandscape: {
  //   marginTop: 100
  // },
  inner: {
    // minWidth: 623,
    // maxWidth: 623,
    width: "100%",
    // height: 707,
    display: "flex",
    height: "100%",
    // flexDirection: 'column',
    justifyContent: "space-between",
    alignItems: "center",
    // marginRight: 30,
    [theme.breakpoints.down("sm")]: {
      minWidth: "auto",
      height: "auto",
      marginRight: 0,
    },
  },
  infoContainer: {
    display: "inherit",
    width: "50%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F4FBFF",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      // height: '100vh',
      justifyContent: "center",
    },
  },
  infoWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // height: '100%'
    // margin: 'auto 20%'
  },
  header: {
    color: "#4096D2",
    display: "inline-block",
    fontFamily: "PT Sans",
    fontSize: 60,
    height: "100%",
    lineHeight: "78px",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      marginBottom: 30,
      width: "98%",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 32,
      lineHeight: "42px",
      marginBottom: 5,
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      height: "auto",
    },
  },
  subHeader: {
    color: "#282560",
    fontFamily: "Open Sans",
    fontSize: 25,
    height: 28,
    lineHeight: "34px",
    width: "70%",
    textAlign: "center",
    display: "inline-block",
    [theme.breakpoints.down("md")]: {
      width: "98%",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      lineHeight: "21px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },
  content: {
    textAlign: "center",
    marginTop: 57,
    font: "normal normal normal 17px/23px Open Sans",
    fontSize: 20,
    color: "#282560",
    // marginLeft: 38,
    width: "70%",
    [theme.breakpoints.down("md")]: {
      width: "99%",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      lineHeight: "18px",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      height: "auto",
      marginTop: 10,
    },
  },
  buttonContainer: {
    display: "flex",
    width: "70%",
    flexDirection: "row",
    justifyContent: "space-around",
    marginTop: 54,
    // marginLeft: 44,
    // marginRight: 38,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginTop: 10,
    },
  },
  button: {
    display: "block",
    minWidth: 200,
    width: "40%",
    height: 54,
    fontFamily: "PT Sans",
    fontSize: 25,
    [theme.breakpoints.down("sm")]: {
      width: "43vw",
      height: 45,
      fontSize: 18,
      margin: 5,
    },
  },
  imageContainer: {
    background: `transparent url(${Logo}) center / contain no-repeat padding-box`,
    opacity: 1,
    width: "50%",
    height: "100%",
    backgroundColor: "#FFFFFF",
    backgroundSize: "60% auto",
    // [theme.breakpoints.down('xl')]: {
    //   minWidth: 579,
    //   height: '80vh'
    // }
  },
  mobileLogo: {
    maxWidth: 400,
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      maxWidth: 200,
    },
  },
  legal: {
    font: "normal normal normal 14px/19px Open Sans",
    textAlign: "center",
    letterSpacing: "0px",
    color: "#9D9DA3",
    width: "75%",
    marginTop: 20,
    [theme.breakpoints.down("xs")]: {
      width: "98%",
      marginTop: 15,
      fontSize: 10,
    },
  },
  strong: {
    color: theme.palette.grey[600],
  },
  strongWarning: {
    color: "red",
    fontWeight: "bold",
    fontSize: "1.25rem",
    lineHeight: 1.25,
    textAlign: "center",
  },
}));

function LandingPage() {
  const classes = useStyles();
  // const [isLandscape, setLandscape] = useState(checkLandscape());

  return (
    <Container maxWidth={"xl"} disableGutters className={clsx(classes.root)}>
      <div className={classes.inner}>
        {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start"
          }}
        >
          <div
            style={{
              width: 217,
              height: 140,
              background: `transparent url(${Logo}) 0% 0% no-repeat padding-box`,
              opacity: 1
            }}
          ></div>
        </div> */}
        <Hidden mdDown>
          <div className={classes.imageContainer}></div>
        </Hidden>
        {/* <div className={classes.logoContainer}></div> */}
        <Container className={classes.infoContainer}>
          <div className={classes.infoWrapper}>
            <Hidden lgUp>
              <img src={Logo} className={classes.mobileLogo} />
            </Hidden>
            <Typography className={classes.header}>WELCOME TO FABRIC</Typography>
            <Typography className={classes.subHeader}>Watch Ads. Get Paid.</Typography>
            <div className={classes.buttonContainer}>
              <Link
                to="#"
                className={clsx(
                  classes.button,
                  "btn",
                  "btn-lg",
                  "brand-text",
                  "brand-btn",
                  "landing-btn"
                )}
              >
                <span style={{ color: "white" }}>Register</span>
              </Link>
              <Link
                to="#"
                className={clsx(
                  classes.button,
                  "btn",
                  "btn-lg",
                  "brand-text",
                  "brand-btn-outline",
                  "landing-btn-outline"
                )}
                style={{
                  borderWidth: "5px",
                  padding: "4px",
                }}
              >
                <span style={{ color: "#EF4137", height: 33 }}>Sign In</span>
              </Link>
            </div>
            <Typography className={classes.strongWarning} style={{ marginTop: 20 }}>
              Fabric is at capacity and our VIP list / Beta-Test is complete. We are currently
              upgrading the platform for a relaunch — please check back soon!
            </Typography>
            {/* <Typography
              className={{ ...classes.legal, ...classes.strong }}
              style={{ marginTop: 15 }}
            >
              Join our VIP Waitlist to be one of the first to receive your card
            </Typography> */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: 25,
              }}
            >
              <p
                style={{
                  color: "#282560",
                  fontFamily: "Open Sans",
                  fontSize: "15px",
                }}
              >
                <a href="https://fabricpay.com" target="_blank" style={{ color: "#EF4137" }}>
                  Home
                </a>{" "}
                |{" "}
                <Link to="/help" style={{ color: "#EF4137" }}>
                  Contact
                </Link>
              </p>
            </div>
            <Typography className={classes.legal}>
              <strong className={classes.strong}>Offer open to U.S. residents only.</strong>
            </Typography>
          </div>
        </Container>
      </div>
    </Container>
  );
}

export default LandingPage;
