import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton,
  useMediaQuery,
} from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import { formatNumber as fN, queryAdsForUser } from "../utils/functions";
import moment from "moment";
import { s3Bucket } from "../appConfig";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    // flexWrap: 'wrap',
    // justifyContent: 'space-around',
    overflow: "hidden",
    backgroundColor: "transparent",
    boxShadow: "0px 1px 12px #E3E3E3",
    // minHeight: 300,
    height: "100%",
    borderRadius: "10px",
    width: "100%",
  },
  contentHeader: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingTop: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(5),
  },
  content: {
    flex: 3,
    height: "100%",
    padding: "0 auto",
    "&:last-child": {
      paddingBottom: 0,
    },
    width: "100%",
    backgroundColor: "#FFFFFF",
    // borderRadius: '10px'
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  inner: {
    height: "100%",
    //minWidth: 250
  },
  chart: {
    height: "100%",
  },
  scrollbar: {
    height: "100%",
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    zIndex: 10,
    // position: 'relative',
    // top: 10,
  },
  "@global": {
    "*::-webkit-scrollbar": {
      display: "none",
    },
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    height: "100%",
    alignItems: "flex-end",
    width: "100%",
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  miniAdHeader: {
    ...theme.typography.h3,
    color: theme.palette.secondary.main,
    fontFamily: "Futura-Medium",
    fontSize: 22,
    fontWeight: "normal",
    lineHeight: "30px",
  },
  miniAdSubHeader: {
    font: "normal normal 600 16px/22px Open Sans",
    color: "#4096D2",
  },
  miniAdText: {
    ...theme.typography.body1,
    color: "#4096D2",
  },
  miniAdCard: {
    height: "100%",
    display: "flex",
    overflow: "hidden",
    position: "relative",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 10,
    maxWidth: 322,
    width: "100%",
    // width: 300
    // paddingBottom: 10
  },
  imageDiv: {
    margin: "5px 0px 10px 0px",
    borderRadius: 14,
    overflow: "hidden",
    maxHeight: 175,
    verticalAlign: "center",
  },
  image: {
    // top: '50%',
    width: "100%",
    position: "relative",
    objectPosition: "center center",
    objectFit: "cover",
    // transform: 'translateY(-50%)'
  },
  miniAdContent: {
    flex: 1,
    alignSelf: "flex-start",
    // marginLeft: '10%'
  },
  tile: {
    height: "100% !important",
    /*width: 300,*/
    overflow: "visible",
  },
  tileChild: {
    overflow: "visible",
  },
  cardHeader: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 5,
  },
}));

const data = {
  thisWeek: {
    data: [],
    labels: [],
  },
  thisMonth: {
    data: [],
    labels: [],
  },
  thisYear: {
    data: [1000, 500, 4500, 2000, 7500, 2800, 1800, 6000, 1300, 10000, 1300, 1550],
    labels: ["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Jan", "Feb", "Mar", "Apr"],
  },
};

const MyTileComponent = ({ tile, coverURL, earnableCoins, ...rest }) => {
  const classes = useStyles();
  const [depth, setDepth] = useState(0);
  return (
    <NavLink to="/ad/requests">
      <Card
        className={classes.miniAdCard}
        elevation={depth}
        // onMouseOver={() => setDepth(4)}
        // onMouseOut={() => setDepth(0)}
      >
        <div className={classes.cardHeader}>
          <Typography className={classes.miniAdSubHeader} variant="h3">
            {tile.company["company/name"]}
          </Typography>
          <Typography className={classes.miniAdText} variant="h3">
            {tile["ad/cashtag"]}
          </Typography>
        </div>
        <div className={classes.imageDiv}>
          <img className={classes.image} src={coverURL} alt={tile.title} />
        </div>
        <div className={classes.miniAdContent}>
          {!!earnableCoins && (
            <Typography className={classes.miniAdHeader} color="secondary">
              Earn {earnableCoins} CA$H
            </Typography>
          )}
        </div>
      </Card>
    </NavLink>
  );
};

function EarningHistory({ className, user, ...rest }) {
  const classes = useStyles();
  const [total, setTotal] = useState(
    data.thisYear.data.slice(8, 12).reduce((a, c) => a + c),
    0
  );
  const [watchedAds, setWatchedAds] = useState([]);
  const [userAds, setUserAds] = useState([]);
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const authToken = window.localStorage.getItem("stringBean");

  useEffect(() => {
    const handle = user["user/handle"];
    queryAdsForUser(handle, authToken)
      .then(({ requestAds, watchedAds }) => {
        setWatchedAds(watchedAds);
        setUserAds(requestAds);
      })
      .catch((err) => {
        debugger;
      });
  }, []);

  return (
    <Card {...rest} className={classes.root}>
      {/* <CardContent className={classes.contentHeader}>
                <div>
                    <Typography variant="h3">Earnings History</Typography>
                    <Typography variant="body1">
                        You earned the most CA$H in February! Keep it up to earn even more!
          </Typography>
                </div>
                <div>
                    <Typography variant="h3">{fN(total)} CA$H</Typography>
                    <Typography variant="body1">Total CA$H earned in 2020!</Typography>
                </div>
            </CardContent> */}
      <CardContent className={classes.content}>
        {/* <PerfectScrollbar className={classes.scrollbar}> */}
        <div className={classes.inner}>
          {/* <Chart
                            className={classes.chart}
                            data={data.thisYear.data}
                            labels={data.thisYear.labels}
                        /> */}
          <GridList className={classes.gridList} spacing={20} cols={isDesktop ? 3.5 : 1.5}>
            {userAds.concat(watchedAds).map((tile) => {
              const userAd = tile.userAd;
              let failedFlag,
                sharedFlag,
                watchedFlag,
                questionsSucceeded,
                consumerData,
                answeredQuestions;
              if (userAd) {
                sharedFlag = userAd["userAd/sharedOnTwitter"];
                watchedFlag = userAd["userAd/watched"];
                failedFlag = userAd.questionFailed;
                questionsSucceeded =
                  userAd.questionsSucceeded &&
                  userAd.questionsSucceeded.map((ans) => ans["answer/question"]._id);
                consumerData =
                  userAd.consumerData &&
                  userAd.consumerData.map((ans) => ans["answer/question"]._id);
                answeredQuestions =
                  (questionsSucceeded && consumerData && questionsSucceeded.concat(consumerData)) ||
                  questionsSucceeded ||
                  consumerData;
              }
              const unansweredQuestions = tile.questions.filter(
                (ques) => !(answeredQuestions && answeredQuestions.includes(ques._id))
              );
              // const answeredQuestions = userAd && (userAd['userAd/questionsSucceeded'] && userAd['userAd/consumerData'] && userAd['userAd/questionsSucceeded'].concat(userAd['userAd/consumerData']) || userAd['userAd/questionsSucceeded'] || userAd['userAd/consumerData'])
              // const answeredQuestionIds = answeredQuestions && answeredQuestions.map(ans => ans._id)

              // const unansweredQuestions = ad.questions.filter(question => !(answeredQuestionIds && answeredQuestionIds.includes(question._id)))
              const adData = tile;
              const videoPoints = adData["ad/videoPoints"];
              const postPoints = adData["ad/postPoints"];
              let earnableCoins = 0;
              earnableCoins += watchedFlag ? 0 : videoPoints;
              earnableCoins += sharedFlag ? 0 : postPoints;
              earnableCoins +=
                unansweredQuestions && unansweredQuestions.length > 0
                  ? unansweredQuestions.reduce(
                      (acc, ques) => ques["question/answerPoints"] + acc,
                      0
                    )
                  : 0;
              earnableCoins = failedFlag ? 0 : earnableCoins;
              const coverImage = tile["ad/coverImage"];
              const coverURL = coverImage.startsWith("http")
                ? coverImage
                : `https://s3.amazonaws.com/${s3Bucket}/` + coverImage;
              return (
                <GridListTile
                  key={tile._id}
                  classes={{ root: classes.tile, tile: classes.tileChild }}
                >
                  <MyTileComponent tile={tile} coverURL={coverURL} earnableCoins={earnableCoins} />
                </GridListTile>
              );
            })}
          </GridList>
        </div>
        {/* </PerfectScrollbar> */}
      </CardContent>
    </Card>
  );
}

EarningHistory.propTypes = {
  className: PropTypes.string,
};

export default EarningHistory;
