import React, { Component } from "react";
import get from "lodash.get";

import ErrorBoundary from "../components/ErrorBoundary";

import { Player, ControlBar, TimeDivider } from "video-react";
import { Col, Row, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Requests from "../components/account/Requests";
import LeftSideBar from "../components/LeftSidebar";
import RightSidebar from "../components/RightSidebar";
import NavIcons from "../components/NavIcons";
import { flureeFetch, getToken } from "../flureeFetch";
import CurrentTimeDisplay from "video-react/lib/components/time-controls/CurrentTimeDisplay";
import PlayToggle from "video-react/lib/components/control-bar/PlayToggle";
import Cashtags from "../components/Cashtags";
import DurationDisplay from "video-react/lib/components/time-controls/DurationDisplay";
import Shortcut from "video-react/lib/components/Shortcut";
import { s3Bucket } from "../appConfig";

class Question extends Component {
  state = {
    correct: null,
    bonusQuestionAnswered: false,
  };

  componentDidMount = () => {
    if (!this.state.sharedOnTwitter) {
      new Promise((resolve, reject) => {
        window.twttr.widgets.load(document.getElementById("twitter-container-b"));
        resolve("Loaded");
      }).then((res) => {
        window.twttr.events.bind("tweet", (e) => this.handleTweet(e));
      });
    }
  };

  componentDidUpdate = () => {
    // console.log(this.state);
    if (!this.state.sharedOnTwitter) {
      new Promise((resolve, reject) => {
        window.twttr.widgets.load(document.getElementById("twitter-container-b"));
        resolve("Loaded");
      }).then((res) => {
        window.twttr.events.bind("tweet", (e) => this.handleTweet(e));
      });
    }
  };

  handleTweet = (e) => {
    window.twttr.events.unbind("tweet");
    const iframe = document.querySelector("iframe");
    iframe && iframe.remove();
    if (!this.state.sharedOnTwitter) {
      this.addPoints(this.props.ad.postPoints, "Twitter")
        .then((res) => {
          this.setState({ sharedOnTwitter: true });
        })
        .catch((err) => {
          return;
        });
    }
  };

  addPoints = (points, socialMedia) => {
    const walletId = get(this.props.user, ["user/wallet", "_id"]);
    const balance = get(this.props.user, ["user/wallet", "wallet/balance"]);
    const earnings = get(this.props.user, ["user/wallet", "wallet/earnings"]);
    const adId = this.props.match.params._id;
    const { budgetRemaining } = this.props.ad;
    if (!this.props.user["user/userAds"]) {
      throw new Error("Loop again and return");
    }
    const userAdId = this.props.user["user/userAds"].find((ad) => {
      return ad["userAd/ad"]["_id"] === parseInt(adId);
    })["_id"];

    let transaction = [
      {
        _id: Number(walletId),
        balance: `#(+ ${balance} ${points})`,
        earnings: `#(+ ${earnings} ${points})`,
      },
    ];

    const tx2 = {
      _id: Number(userAdId),
    };

    tx2[`sharedOn${socialMedia}`] = true;

    const tx3 = {
      _id: Number(adId),
      budgetRemaining: `#(- ${budgetRemaining} ${points})`,
    };

    transaction.push(tx2);
    transaction.push(tx3);

    const authToken = getToken();

    return flureeFetch("/transact", transaction, authToken)
      .then((res) => {
        let newState = {};
        newState[`sharedOn${socialMedia}`] = true;
        this.setState(newState);
      })
      .catch((err) => this.setState({ error: err }));
  };

  checkAnswer = () => {
    const { correctAnswer } = this.props.ad;
    const { selectedAnswer } = this.state;
    if (correctAnswer === selectedAnswer) {
      this.answeredQuestionUpdate(true);
    } else {
      this.answeredQuestionUpdate(false);
    }
  };

  answeredQuestionUpdate = (correct) => {
    const wallet_id = get(this.props.user, ["user/wallet", "_id"]);
    const balance = get(this.props.user, ["user/wallet", "wallet/balance"]);
    const earnings = get(this.props.user, ["user/wallet", "wallet/earnings"]);

    const ad = this.props.ad;
    const ad_id = ad._id;
    const budgetRemaining = ad.budgetRemaining;
    const { questionPoints, videoPoints } = this.props.ad;
    const { userAd } = this.props;
    let transaction;

    if (correct) {
      transaction = [
        {
          _id: wallet_id,
          balance: `#(+ ${balance} ${questionPoints})`,
          earnings: `#(+ ${earnings} ${questionPoints})`,
        },
        {
          _id: Number(ad_id),
          budgetRemaining: `#(- ${budgetRemaining} ${questionPoints})`,
        },
        {
          _id: userAd,
          questionStatus: "correct",
          pointsEarned: `#(+ ${videoPoints} ${questionPoints})`,
          watched: true,
        },
      ];
    } else {
      transaction = [
        {
          _id: userAd,
          questionStatus: "incorrect",
          watched: true,
        },
      ];
    }
    const authToken = getToken();
    flureeFetch("/transact", transaction, authToken)
      .then((res) => {
        this.setState({ completed: true, correct: correct });
        this.props.refreshUser();
      })
      .catch((err) => this.setState({ error: err }));
  };

  handleChange = (answer) => {
    this.setState({ selectedAnswer: answer });
  };

  returnToRequests = () => {
    this.props.history.push("/account/view-requests");
  };

  render() {
    const {
      question,
      answerChoices,
      videoPoints,
      questionPoints,
      postPoints,
      bonusQuestion,
      bonusQuestionAnswerChoices,
    } = this.props.ad;
    const fabricURI = window.location.href;
    const cashtag = this.props.ad.cashtag[0];
    const hashtag = cashtag && cashtag[0] === "$" ? cashtag.slice(1) : cashtag;
    return (
      <div id="twitter-container-b" style={{ background: "#F3F3F3", padding: "20px" }}>
        {this.state.correct === null ? (
          <>
            <p style={{ fontSize: "12px", marginBottom: "0px" }}>
              You earned {videoPoints} points for watching this video.
            </p>
            <p className="text-center">
              Answer this question for +<strong>{questionPoints} SocialCash</strong>
            </p>
            <h3 className="text-center" style={{ fontWeight: 320 }}>
              {question}
            </h3>
            <div className="text-center">
              {answerChoices.map((answer) => (
                <div style={{ display: "inline-block" }}>
                  <div className="answerCircle" onClick={() => this.handleChange(answer)}>
                    {this.state.selectedAnswer === answer ? (
                      <i className="fas fa-check answerCheck"></i>
                    ) : null}
                  </div>
                  <div> {answer} </div>
                </div>
              ))}
            </div>
            <div className="text-center" style={{ marginTop: "10px" }}>
              <Button onClick={() => this.checkAnswer()}>Submit</Button>
            </div>
          </>
        ) : null}
        {this.state.correct === true && bonusQuestion && !this.state.bonusQuestionAnswered ? (
          <div>
            <p style={{ fontSize: "12px", marginBottom: "0px" }}>
              You earned {questionPoints} points for answering this question.
            </p>
            <p className="text-center">Would you like to answer this bonus question for?</p>
            <h3 className="text-center" style={{ fontWeight: 320 }}>
              {bonusQuestion}
            </h3>
            <div className="text-center">
              {bonusQuestionAnswerChoices.map((answer) => (
                <div style={{ display: "inline-block" }}>
                  <div className="answerCircle" onClick={() => this.handleChange(answer)}>
                    {this.state.selectedAnswer === answer ? (
                      <i className="fas fa-check answerCheck"></i>
                    ) : null}
                  </div>
                  <div> {answer} </div>
                </div>
              ))}
            </div>
            <div className="text-center" style={{ marginTop: "10px" }}>
              <Button onClick={() => this.setState({ bonusQuestionAnswered: true })}>Submit</Button>
            </div>
          </div>
        ) : null}
        {this.state.correct && !bonusQuestion && (
          <div className="text-center">
            <i className="fas fa-trophy" style={{ fontSize: "100px", color: "#bfbfbf" }}></i>
            <h2 style={{ fontWeight: 300 }}>Congratulations!</h2>
            <h2 style={{ fontWeight: 300 }}>
              You have earned <strong>{questionPoints} CA$H Tokens!</strong>
            </h2>
            <div>
              <Button onClick={this.returnToRequests}>Return to Requests</Button>
            </div>
            {postPoints && (
              <>
                {this.state.sharedOnTwitter ? (
                  <h2>Thanks for Sharing!</h2>
                ) : (
                  <>
                    <h2>-- or --</h2>
                    <h2>
                      Tweet About This Ad for Another <strong>{postPoints} CA$H Tokens!</strong>
                    </h2>
                  </>
                )}
                <div>
                  <ErrorBoundary>
                    <a
                      className="twitter-share-button"
                      href="https://twitter.com/intent/tweet"
                      data-size="large"
                      data-text="Check this out!"
                      data-url={fabricURI}
                      data-hashtags={hashtag}
                      data-via="_FabricPay_"
                    >
                      {/* <Button><i className="fab fa-twitter" style={{ color: "White" }} />&nbsp;Share on Twitter</Button> */}
                    </a>
                  </ErrorBoundary>
                </div>
              </>
            )}
          </div>
        )}
        {this.state.correct && this.state.bonusQuestionAnswered && (
          <div className="text-center">
            <i className="fas fa-trophy" style={{ fontSize: "100px", color: "#bfbfbf" }}></i>
            <h2 style={{ fontWeight: 300 }}>Thank you for answering this bonus question!</h2>
            <h2 style={{ fontWeight: 300 }}>
              You have earned <strong>{questionPoints} CA$H Tokens!</strong>
            </h2>
            <div>
              <Button onClick={this.returnToRequests}>Return to Requests</Button>
            </div>
            {postPoints && (
              <>
                {this.state.sharedOnTwitter ? (
                  <h2>Thanks for Sharing!</h2>
                ) : (
                  <>
                    <h2>-- or --</h2>
                    <h2>
                      Tweet About This Ad for Another <strong>{postPoints} CA$H Tokens!</strong>
                    </h2>
                  </>
                )}
                <div>
                  <ErrorBoundary>
                    <a
                      className="twitter-share-button"
                      href="https://twitter.com/intent/tweet"
                      data-size="large"
                      data-text="Check this out!"
                      data-url={fabricURI}
                      data-hashtags={hashtag}
                      data-via="_FabricPay_"
                    >
                      {/* <Button><i className="fab fa-twitter" style={{ color: "White" }} />&nbsp;Share on Twitter</Button> */}
                    </a>
                  </ErrorBoundary>
                </div>
              </>
            )}
          </div>
        )}
        {this.state.correct === false && (
          <div className="text-center">
            <i className="fas fa-times-circle" style={{ fontSize: "100px", color: "#bfbfbf" }}></i>
            <h2 style={{ fontWeight: 300 }}>Sorry!</h2>
            <h2 style={{ fontWeight: 300 }}>That was the wrong answer.</h2>
            <div>
              <Button onClick={this.returnToRequests}>Return to Requests</Button>
            </div>
            {postPoints && (
              <>
                {this.state.sharedOnTwitter ? (
                  <h2>Thanks for Sharing!</h2>
                ) : (
                  <>
                    <h2>-- or --</h2>
                    <h2>
                      Tweet About This Ad for Another <strong>{postPoints} CA$H Tokens!</strong>
                    </h2>
                  </>
                )}
                <div>
                  <ErrorBoundary>
                    <a
                      className="twitter-share-button"
                      href="https://twitter.com/intent/tweet"
                      data-size="large"
                      data-text="Check this out!"
                      data-url={fabricURI}
                      data-hashtags={hashtag}
                      data-via="_FabricPay_"
                    >
                      {/* <Button><i className="fab fa-twitter" style={{ color: "White" }} />&nbsp;Share on Twitter</Button> */}
                    </a>
                  </ErrorBoundary>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

class AdVideo extends Component {
  state = {
    completed: false,
  };

  componentDidMount() {
    this.refs.player.subscribeToStateChange(this.handleStateChange.bind(this));
  }

  watchedVideoUpdate = (points) => {
    const ad = this.props.ad;
    const ad_id = ad._id;
    const adBudgetRemaining = ad.budgetRemaining;
    const wallet_id = get(this.props.user, ["user/wallet", "_id"]);
    const balance = get(this.props.user, ["user/wallet", "wallet/balance"]);
    const earnings = get(this.props.user, ["user/wallet", "wallet/earnings"]);

    const transaction = [
      {
        _id: wallet_id,
        balance: `#(+ ${balance} ${points})`,
        earnings: `#(+ ${earnings} ${points})`,
      },
      {
        _id: "userAd$1",
        ad: Number(ad_id),
        watched: true,
        watchDate: "#(now)",
        pointsEarned: points,
      },
      {
        _id: Number(ad_id),
        budgetRemaining: `#(- ${adBudgetRemaining} ${points})`,
      },
      {
        _id: this.props.user._id,
        userAds: ["userAd$1"],
      },
    ];
    const authToken = getToken();
    flureeFetch("/transact", transaction, authToken)
      .then((res) => {
        let userAd_id = get(res, ["tempids", "userAd$1"]);
        this.setState({ userAd_id: userAd_id });
      })
      .then((res) => this.props.refreshUser())
      .catch((err) => this.setState({ error: err }));
  };

  handleStateChange(state, prevState) {
    if (state.currentTime === state.duration && state.completed !== true) {
      this.setState({ completed: true });
      const { videoPoints } = this.props.ad;
      this.watchedVideoUpdate(videoPoints);
    }
  }

  // Override 'l' to jump forward
  // Override 'Shift + >' to increase speed
  // Ctrl/Cmd + Right arrow to go forward 30 seconds
  newShortcuts = [
    { keyCode: 76, handle: () => {} },
    { keyCode: 190, shift: true, handle: () => {} },
    { keyCode: 39, handle: () => {} },
    {
      keyCode: 39,
      ctrl: true,
      handle: (player, actions) => {
        const operation = { action: "forward-30", source: "shortcut" };
        actions.forward(2000, operation);
      },
    },
  ];

  render() {
    const { ad, user, refreshUser } = this.props;
    const { userAd_id } = this.state;
    const { coverImage, videoURL } = ad;
    let coverURL, sourceURL;
    if (coverImage) {
      coverURL = coverImage.startsWith("http")
        ? coverImage
        : `https://s3.amazonaws.com/${s3Bucket}/` + coverImage;
    }
    if (videoURL) {
      sourceURL = videoURL.startsWith("http")
        ? videoURL
        : `https://s3.amazonaws.com/${s3Bucket}/` + videoURL;
    }
    return (
      <div
        style={{
          height: 438,
          width: 840,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 25,
          marginBottom: 25,
        }}
      >
        {this.state.completed ? (
          <div>
            <Question
              ad={ad}
              user={user}
              userAd={userAd_id}
              refreshUser={refreshUser}
              {...this.props}
            />
          </div>
        ) : (
          <Player
            poster={coverURL}
            startTime={0}
            videoId="vid-player"
            ref="player"
            clickable={false}
            onKeyPress={this.handleKeyPress}
            autoPlay
          >
            <source src={sourceURL} />
            <Shortcut shortcuts={this.newShortcuts} />
            <ControlBar disableDefaultControls={true}>
              <PlayToggle />
              <CurrentTimeDisplay />
              <TimeDivider />
              <DurationDisplay />
            </ControlBar>
          </Player>
        )}
      </div>
    );
  }
}

class Ad extends Component {
  state = {
    trendingInfluencers: [],
  };

  // componentDidMount() {
  //   const profilePic = get(this.props.user, "user/profilePic");
  //   const profilePicPath = profilePic.match(/^http/)
  //     ? profilePic
  //     : `https://s3.amazonaws.com/${s3Bucket}/${profilePic}`;
  //   this.setState({ profilePic: profilePicPath });
  //   // this.updateAdAndTrending();
  // }

  handleTweet = (e) => {
    // if (!this.state.sharedOnTwitter) {
    //   this.addPoints(this.state.ad.postPoints, "Twitter")
    // }
  };

  componentDidUpdate() {
    // new Promise((resolve, reject) => {
    //   window.twttr.widgets.load(document.getElementById('twitter-container-a'))
    //   resolve("Loaded")
    // })
    //   .then(res => {
    //     window.twttr.events.bind('tweet', (e) => this.handleTweet(e))
    //   })
    // if (this.state._id !== this.props.match.params._id) {
    //   this.updateAdAndTrending()
    // }
  }

  addPoints = (points, socialMedia) => {
    const walletId = get(this.props.user, ["user/wallet", "_id"]);
    const balance = get(this.props.user, ["user/wallet", "wallet/balance"]);
    const earnings = get(this.props.user, ["user/wallet", "wallet/earnings"]);
    const adId = this.props.match.params._id;
    const { budgetRemaining } = this.state.ad;
    const userAdId = this.props.user["user/userAds"].find((ad) => {
      return ad["userAd/ad"]["_id"] === parseInt(adId);
    })["_id"];

    let transaction = [
      {
        _id: Number(walletId),
        balance: `#(+ ${balance} ${points})`,
        earnings: `#(+ ${earnings} ${points})`,
      },
    ];

    const tx2 = {
      _id: Number(userAdId),
    };

    tx2[`sharedOn${socialMedia}`] = true;

    const tx3 = {
      _id: Number(adId),
      budgetRemaining: `#(- ${budgetRemaining} ${points})`,
    };

    transaction.push(tx2);
    transaction.push(tx3);
    const authToken = getToken();
    flureeFetch("/transact", transaction, authToken)
      .then((res) => this.props.refreshUser())
      .then((res) => {
        let newState = {};
        newState[`sharedOn${socialMedia}`] = true;
        this.setState(newState);
      })
      .catch((err) => this.setState({ error: err }));
  };

  generateAdUsers = () => {
    let list = [];
    for (let i = 9; i < 18; i++) {
      list.push(
        <img
          className="ad-user img-responsive img-circle"
          src={require(`../assets/img/users/${i}.jpg`)}
          alt=""
        />
      );
    }

    return list;
  };

  updateAdAndTrending() {
    const _id = this.props.match.params._id;

    const adQuery = {
      trending: {
        select: {
          "?user": ["*"],
        },
        where: [
          ["?user", "user/wallet", "?wallet"],
          ["?wallet", "wallet/balance", "?balance"],
        ],
        filter: ["(> ?balance 50)"],
        limit: 20,
      },
      ad: { select: ["*", { company: ["*"] }], from: Number(_id) }, // TODO: NEED TO ADJUST FOR WHEN USER HAS WATCHED AD
      watched: {
        selectOne: ["?watched", "?tweeted"],
        where: [
          ["?user", "user/handle", this.props.user["user/handle"]],
          ["?ad", "ad/finished", "?finished", { filter: "(and ?finished)" }],
          ["?user", "user/userAds", "?userAd"],
          ["?userAd", "userAd/ad", Number(_id)],
          ["?userAd", "userAd/watched", "?watched"],
          ["?userAd", "userAd/sharedOnTwitter", "?tweeted"],
        ],
      },
    };
    const authToken = getToken();
    flureeFetch("multi-query", adQuery, authToken).then((res) => {
      const ad = get(res, "ad");
      const videoURL = get(ad, "ad/video");
      const coverImage = get(ad, "ad/coverImage");
      const questionPoints = get(ad, "ad/questionPoints");
      const videoPoints = get(ad, "ad/videoPoints");
      const question = get(ad, "ad/question");
      const answerChoices = get(ad, "ad/answerChoices");
      const correctAnswer = get(ad, "ad/correctAnswer");
      const bonusQuestion = get(ad, "ad/bonusQuestion");
      const bonusQuestionAnswerChoices = get(ad, "ad/bonusQuestionAnswerChoices");
      const tagline = get(ad, "ad/tagline");
      const cashtag = get(ad, "ad/cashtag");
      const budgetRemaining = get(ad, "ad/budgetRemaining");
      const companyName = get(ad, ["company", "company/name"]);
      const companyLogo = get(ad, ["company", "company/logo"]);
      const postPoints = get(ad, "ad/postPoints");

      const trendingInfluencers = get(res, "trending");
      if (res.watched && res.watched[0]) {
        this.setState({
          trendingInfluencers,
          _id,
          ad: {
            _id,
            budgetRemaining,
            cashtag,
            tagline,
            companyName,
            postPoints,
          },
          watched: true,
          sharedOnTwitter: res.watched[1],
        });
      } else {
        this.setState({
          trendingInfluencers: trendingInfluencers,
          ad: {
            _id: _id,
            videoURL: videoURL,
            coverImage: coverImage,
            bonusQuestion: bonusQuestion,
            bonusQuestionAnswerChoices: bonusQuestionAnswerChoices,
            questionPoints: questionPoints,
            videoPoints: videoPoints,
            question: question,
            answerChoices: answerChoices,
            correctAnswer: correctAnswer,
            companyName: companyName,
            companyLogo: companyLogo,
            tagline: tagline,
            cashtag: cashtag,
            budgetRemaining,
            postPoints,
          },
          _id: _id,
        });
      }
    });
  }

  returnToRequests = () => {
    this.props.history.push("/account/view-requests");
  };

  render() {
    const logo = get(this.state.ad, "companyLogo");
    let logoURL;
    // console.log('logo:', logo);
    if (logo) {
      logoURL = logo.startsWith("http") ? logo : `https://s3.amazonaws.com/${s3Bucket}/` + logo;
    }
    const companyName = get(this.state.ad, "companyName");
    const tagline = get(this.state.ad, "tagline");
    const budgetRemaining = get(this.state.ad, "budgetRemaining");
    const cashtag = get(this.state.ad, "cashtag");
    const hashtag = cashtag && cashtag[0] === "$" ? cashtag.slice(1) : cashtag;
    const coverImage = this.state.ad && this.state.ad.coverImage;
    const fabricURI = window.location.href;
    const state = this.state;
    const props = this.props;
    const userAds = this.props.user["user/userAds"];
    let watched;
    if (userAds) {
      watched = false; // this.props.user['user/userAds'][0] && !!this.props.user['user/userAds'][0]._id
    }
    // if (!(this.props.match.params._id === 'level-up') && !(this.props.match.params._id === 'requests')) {
    //   debugger
    // }

    if (this.props.match.params._id === "level-up") {
      return (
        <>
          {/* main dashboard content */}
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                width: 615,
                height: 400,
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontFamily: "Open Sans",
                  fontSize: 25,
                  lineHeight: "34px",
                  letterSpacing: 0,
                  color: "#282560",
                  opacity: 1,
                }}
              >
                Your Smart Profile is
              </p>
              <p
                style={{
                  fontFamily: "PT Sans",
                  fontSize: 60,
                  lineHeight: "78px",
                  letterSpacing: 0,
                  color: "#4096D2",
                  opacity: 1,
                }}
              >
                Level 3
              </p>
              <p
                style={{
                  textAlign: "center",
                  font: "Regular 17px/23px Open Sans",
                  letterSpacing: 0,
                  color: "#282560",
                  opacity: 1,
                }}
              >
                You can increase your level by participating in Fabric’s data-driven ad
                <br />
                campaigns and by providing information to Fabric’s consumer questions.
              </p>
              <NavLink to="/ad/requests">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#EF4137 0% 0% no-repeat padding-box",
                    opacity: 1,
                    width: 355,
                    height: 78,
                  }}
                >
                  <p
                    style={{
                      fontFamily: "PT Sans",
                      fontSize: 20,
                      lineHeight: "26px",
                      color: "white",
                      opacity: 1,
                    }}
                  >
                    View Available Ads
                  </p>
                </div>
              </NavLink>
            </div>
          </div>

          {/* small nav buttons */}
          <div style={{ position: "absolute", top: 20, right: 20, zIndex: 100 }}>
            <NavIcons user={this.props.user} imgSrc={this.state.profilePic} />
          </div>
        </>
      );
    }

    if (this.props.match.params._id === "requests") {
      return <Requests {...this.props} />;
    }

    return (
      <>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {this.state.ad && !this.state.watched && (
            <>
              <p
                style={{
                  fontFamily: "PT Sans",
                  fontSize: 60,
                  lineHeight: "78px",
                  letterSpacing: 0,
                  color: "#4096D2",
                  opacity: 1,
                }}
              >
                {companyName && companyName.toUpperCase()}
              </p>
              <p
                style={{
                  fontFamily: "Open Sans",
                  fontSize: 30,
                  lineHeight: "41px",
                  letterSpacing: 0,
                  color: "#282560",
                  opacity: 1,
                  textAlign: "center",
                }}
              >
                {tagline}
              </p>
              {watched ? (
                <div
                  id="twitter-container-a"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "20vh",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 40,
                    marginBottom: 40,
                  }}
                >
                  <Col>
                    <h3>You've Already Watched This Video</h3>
                  </Col>
                  <Col>
                    <Button onClick={this.returnToRequests} className="brand-btn">
                      Return to Ad Requests
                    </Button>
                  </Col>
                  <Col style={{ marginTop: "10px" }}>
                    {this.state.sharedOnTwitter ? (
                      <Button className="btn btn-primary" disabled>
                        <i className="fab fa-twitter" style={{ color: "White" }} />
                        &nbsp;Thanks for posting!
                      </Button>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <p>Earn {this.state.ad.postPoints} By Tweeting About This Ad!</p>
                        <ErrorBoundary>
                          <a
                            className="twitter-share-button"
                            href="https://twitter.com/intent/tweet"
                            data-size="large"
                            data-text="Check this out!"
                            data-url={fabricURI}
                            data-hashtags={hashtag}
                            data-via="_FabricPay_"
                          >
                            <Button>
                              <i className="fab fa-twitter" style={{ color: "White" }} />
                              &nbsp;Share on Twitter
                            </Button>
                          </a>
                        </ErrorBoundary>
                      </div>
                    )}
                  </Col>
                </div>
              ) : (
                <AdVideo
                  ad={this.state.ad}
                  user={this.props.user}
                  refreshUser={this.props.refreshUser}
                  {...this.props}
                />
              )}
              <p
                style={{
                  font: "Italic 30px/41px Open Sans",
                  letterSpacing: 0,
                  color: "#282560",
                  opacity: 1,
                }}
              >
                {cashtag}
              </p>
            </>
          )}
        </div>

        <div style={{ position: "absolute", top: 20, right: 20, zIndex: 100 }}>
          <NavIcons user={this.props.user} imgSrc={this.state.profilePic} />
        </div>
      </>
    );
    /* <div style={{
          height: 438, width: 840, display: 'flex', justifyContent: 'center', alignItems: 'center',
          background: coverImage && `transparent url(https://s3.amazonaws.com/${s3Bucket}/${coverImage}) center/contain no-repeat padding-box`, boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px'
        }}>
        </div> */

    return (
      <section className="profile-two" style={{ backgroundColor: "white" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2">
              {/* <Cashtags /> */}
              <LeftSideBar {...this.props} />
            </div>
            <div className="col-lg-7">
              {this.state.ad && !this.state.watched && (
                <Row>
                  <Col sm={{ span: 11 }} className>
                    <Row
                      className="justify-content-between align-self-end"
                      style={{ margin: "10px 0px" }}
                    >
                      <Col
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            height: "50px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-end",
                          }}
                        >
                          <img src={logoURL} style={{ maxHeight: "100%" }} />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p style={{ fontWeight: 500 }}>&nbsp;&nbsp;{companyName}</p>
                        </div>
                      </Col>
                      {/* <Col xs={4} style={{textAlign: "right", minHeight: "100%"}}><p>Sponsored</p></Col> */}
                    </Row>
                  </Col>
                  <Col sm={{ span: 11 }}>
                    <AdVideo
                      ad={this.state.ad}
                      user={this.props.user}
                      refreshUser={this.props.refreshUser}
                      {...this.props}
                    />
                    <div className="row" style={{ marginTop: "20px" }}>
                      <div className="col-sm-5">
                        <p>{budgetRemaining} CA$H in Ad Campaign</p>
                      </div>
                      <div className="col-sm-7" style={{ textAlign: "right" }}>
                        <div>{this.generateAdUsers()}</div>
                      </div>
                    </div>
                    <div style={{ marginTop: "20px" }}>
                      <div>
                        <strong>{companyName}</strong> {tagline}
                      </div>
                      <div>
                        <strong style={{ fontSize: "16px" }}>{cashtag}</strong>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
              {this.state.watched && (
                <div
                  id="twitter-container-a"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "80vh",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Col>
                    <h3>You've Already Watched This Video</h3>
                  </Col>
                  <Col>
                    <Button onClick={this.returnToRequests} className="brand-btn">
                      Return to Ad Requests
                    </Button>
                  </Col>
                  <Col style={{ marginTop: "10px" }}>
                    {this.state.sharedOnTwitter ? (
                      <Button className="btn btn-primary" disabled>
                        <i className="fab fa-twitter" style={{ color: "White" }} />
                        &nbsp;Thanks for posting!
                      </Button>
                    ) : (
                      <div>
                        <p>Earn {this.state.ad.postPoints} By Tweeting About This Ad!</p>
                        <ErrorBoundary>
                          <a
                            className="twitter-share-button"
                            href="https://twitter.com/intent/tweet"
                            data-size="large"
                            data-text="Check this out!"
                            data-url={fabricURI}
                            data-hashtags={hashtag}
                            data-via="_FabricPay_"
                          >
                            <Button>
                              <i className="fab fa-twitter" style={{ color: "White" }} />
                              &nbsp;Share on Twitter
                            </Button>
                          </a>
                        </ErrorBoundary>
                      </div>
                    )}
                  </Col>
                </div>
              )}
            </div>
            <div className="col-lg-3">
              {/* <RightSidebar trendingInfluencers={this.state.trendingInfluencers} /> */}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Ad;
