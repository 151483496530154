import fetch from "isomorphic-fetch";
import { serverURL } from "./appConfig";
import { trackPromise } from "react-promise-tracker";

function isDevelopment() {
  return process.env.NODE_ENV === "development";
}


function parseJSON(response) {
  return response.json().then(function (json) {
    const newResponse = Object.assign(response, { json });
    if (response.status < 300) {
      return newResponse;
    } else {
      throw newResponse.json;
    }
  });
}

// function newToken() {
//   return trackPromise(
//     fetch(`${serverURL}get-fluree-token`, { method: "GET" })
//       .then(parseJSON)
//       .then((res) => {
//         let token = res.json.token;
//         localStorage.setItem("fabricToken", token);
//         // debugger;
//         return token;
//       })
//       .catch((err) => {
//         // debugger;
//         let errorMessage = (err.json && err.json.message) || err;
//         throw new Error(errorMessage);
//       })
//   );
// }

function getToken() {
  const authToken = window.localStorage.getItem("stringBean");
  return authToken;
}

function fetchResp(fullUri, fetchOpts, isDev) {
  return trackPromise(
    fetch(fullUri, fetchOpts)
      .then(parseJSON)
      .then((resp) => resp.json)
      .catch((err) => {
        debugger;
        if (!err) {
          throw new Error("Error");
        }
        let errorMessage = err.message || (err.json && err.json.message) || err.json || err;
        throw new Error(errorMessage);
      })
  );
}

var flureeFetch = (uri, body = undefined, token = undefined) => {
  const isDev = isDevelopment();
  // const gateway = isDev ? "http://localhost:8090/fdb" : "https://db.flur.ee/api/db";
  const gateway = `${serverURL}ledger`;
  debugger;
  let fullUri;
  if (uri === "/new-db" || uri === "/dbs") {
    fullUri = gateway + "/db-check" + uri;
  } else {
    fullUri = gateway + "/app/" + uri;
  }
  debugger;

  const fetchOpts = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };
  if (body) fetchOpts.body = JSON.stringify(body);
  if (token) {
    fetchOpts.headers = { ...fetchOpts.headers, authorization: `Bearer ${token}` };
  }
  debugger;

  return trackPromise(
    new Promise((resolve, reject) => {
      if (!isDev) {
        // getToken()
        //   .then(res => (fetchOpts.headers["Authorization"] = `Bearer ${res}`))
        // .then(res =>
        fetchResp(fullUri, fetchOpts)
          // )
          .then((res) => {
            debugger;
            // console.log(res);
            if (Object.keys(res).includes("result")) {
              return resolve(res.result);
            } else {
              return resolve(res);
            }
          })
          .catch((err) => {
            debugger;
            reject(err);
          });
      } else {
        fetchResp(fullUri, fetchOpts)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      }
    })
  );
};

export { flureeFetch, parseJSON, getToken };
