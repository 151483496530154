import React, { Component } from 'react';
import { flureeFetch } from '../../flureeFetch';
import get from 'lodash.get';
import { Button } from 'react-bootstrap';

class CategoryChildren extends Component {
    state = {}

    renderCategoryMap(path, depth){
        const { categoryMap } = this.props;     // Do not delete, used to render map
        const pathString = path.join("][")
        const getChildren = "categoryMap[" + pathString + "]"
        const children = eval(getChildren)

        let html = children.map((child, idx) => {
            const displayName = get(child, "category/displayName");
            const descriptiveName = get(child, "category/descriptiveName");
            const selected = get(child, "selected");
            const children = get(child, "children", null)
            const leftMargin = Number(depth) * 20
            if(selected && children){
                return (
                    <div key={descriptiveName}>
                    <div style={{marginLeft: `${leftMargin}px`}}
                        className="selected category"
                        onClick={() => this.props.unselectHigherLevel([...path, idx])}>
                        <i className="fas fa-minus closeCat" />
                        <p>{displayName}</p></div>
                    {this.renderCategoryMap([...path, idx, "\"children\""],  depth + 1)}
                </div>
                )
            } else if (selected) {
                return(
                    <div key={descriptiveName} style={{marginLeft: `${leftMargin}px`}}
                        className="selected category"
                        onClick={() => this.props.unselectChild([...path, idx])}>
                        <i className="fas fa-minus closeCat" />
                        <p>{displayName}</p></div>
                )
            } else {
                return (
                    <div key={descriptiveName} style={{marginLeft: `${leftMargin}px`}}
                        className="category" onClick={() => this.props.selectNextChildren([...path, idx])}>
                        {displayName}
                    </div>
                )
            }
        })
        return html
    }

    render(){
        return(
            <div>
                {   this.props.categoryMap
                    ?
                    this.renderCategoryMap(["\"children\""], 1)
                    :
                    null
                }
            </div>
        )
    }
}

class CategoryMap extends Component {
    state = {
       categoryMap: {}
    }

    componentDidMount(){
        const categoryMap = {} 
        const { category } = this.props
        const displayName = get(category, "category/displayName");
        const descriptiveName = get(category, "category/descriptiveName");
        const children = get(category, "children");
        categoryMap[displayName] = {
            "selected": true,
            "category/descriptiveName": descriptiveName,
            "children": children
        }

        this.setState({displayName: displayName, categoryMap: categoryMap, selectedCategory: descriptiveName})
    }

    selectNextChildren(path){
        const { categoryMap, displayName } = this.state;

        // Check that no other children at that level have been selected. 
        const pathToChildren = path.slice(0, -1)
        const pathToChildrenString = pathToChildren.join("][")
        const getAllLevelChildren = "categoryMap[\"" + displayName + "\"]["+ pathToChildrenString + "]"
        const allLevelChildren = eval(getAllLevelChildren)
        const filteredChildren = allLevelChildren.findIndex(child => child.selected === true)

        // Update Category Map, get new selectedCategory and priceRanges
        const pathString = path.join("][")
        const getDescriptive = "categoryMap[\"" + displayName + "\"]["+ pathString + "][\"category/descriptiveName\"]"
        const selectedCategory = eval(getDescriptive)
        const setSelected = "categoryMap[\"" + displayName + "\"]["+ pathString + "][\"selected\"] = true"
        const getPriceRanges = "categoryMap[\"" + displayName + "\"]["+ pathString + "][\"category/priceRanges\"]"
        eval(setSelected)
        const priceRanges = eval(getPriceRanges)

        // If relevant, unselect already selected child
        if(filteredChildren !== -1 ){
            const unselect = "categoryMap[\"" + displayName + "\"]["+ pathToChildrenString + "][" + filteredChildren + "][\"selected\"] = false"
            eval(unselect)
        } 

        this.setState({categoryMap: categoryMap, selectedCategory: selectedCategory, priceRanges: priceRanges})
    }

    unselectChild(path){
        const { categoryMap, displayName } = this.state;

        // Get new selectedCategory and new priceRanges
        const pathToParent = path.slice(0, -2);
        const pathToParentString = pathToParent.join("][")
        let getParent; 
        
        if(pathToParentString){
            getParent = "categoryMap[\"" + displayName + "\"]["+ pathToParentString + "]";
        } else {
            getParent = "categoryMap[\"" + displayName + "\"]"
        }

        const parentMap = eval(getParent)
        const selectedCategory = get(parentMap, "category/descriptiveName")
        const priceRanges = get(parentMap, "category/priceRanges")


        //unselectChild
        const pathString = path.join("][")
        const unselectChild = "categoryMap[\"" + displayName + "\"]["+ pathString + "][\"selected\"] = false"
        eval(unselectChild)

        this.setState({categoryMap: categoryMap, selectedCategory: selectedCategory, priceRanges: priceRanges})
    }

    unselectHigherLevel(path){
        const { categoryMap, displayName } = this.state;
        // Get new selectedCategory and new priceRanges
        const pathToParent = path.slice(0, -1);
        const pathToParentString = pathToParent.join("][")
        const getParent = "categoryMap[\"" + displayName + "\"]["+ pathToParentString + "]"
        const parentMap = eval(getParent)
        const selectedCategory = get(parentMap, "category/descriptiveName")
        const priceRanges = get(parentMap, "category/priceRanges")


        //unselectChild
        const pathString = path.join("][")
        const unselectChild = "categoryMap[\"" + displayName + "\"]["+ pathString + "][\"selected\"] = false"
        eval(unselectChild)

        this.setState({categoryMap: categoryMap, selectedCategory: selectedCategory, priceRanges: priceRanges})
    }

    getPriceRanges(){
        let { priceRanges, selectedPriceRange } = this.state
        priceRanges = priceRanges.split(", ")
        return priceRanges.map(range => {
            if(range === selectedPriceRange) {
               return <div key={range} className="selected category">{range}</div>
            } 
            return <div key={range} className="category" onClick={() => this.setState({selectedPriceRange: range})}>{range}</div>
        })
    }

    setPurchase = () => {
        const { selectedCategory, selectedTimeRange, selectedPriceRange } = this.state;
        const userId = this.props.user._id
        const transaction = [{
            _id: userId,
            shoppingInterests: [["category/descriptiveName", selectedCategory]],
            purchases: [{_id: "purchase",
                        category: ["category/descriptiveName", selectedCategory],
                        priceRange: selectedPriceRange,
                        timeRange: selectedTimeRange }]
        }]

        // If selected category/descriptiveName = clothing.mens.business.suits.designer, add 3 ads
        if(selectedCategory === "clothing.mens.business.suits.designer"){
            transaction[0]["userAds"] = [{_id: "userAd",
                                        watched: false,
                                        id: 7,
                                        ad:  ["ad/id", 7]},
                                        {_id: "userAd",
                                        watched: false,
                                        id: 8,
                                        ad:  ["ad/id", 8]},
                                        {_id: "userAd",
                                        watched: false,
                                        id: 9,
                                        ad:  ["ad/id", 9]}]
        }

        flureeFetch("/transact", transaction)
        .then(res => {
            this.props.history.push("/account/view-requests")
        })
    }

    render(){
        const timeRanges = ["1 Week", "3 Weeks", "1 Month", "3 Months", "Longer than 3 Months"]
        const { category } = this.props
        const displayName = get(category, "category/displayName")
        const { selectedTimeRange, priceRanges } = this.state
        return(
            <div>
                <div className="col-sm-12" style={{marginBottom: "20px"}}>
                    <div style={{paddingBottom: "10px"}}>
                        <div className="col-sm-8">
                            <Button onClick={this.props.resetPurchase}>Restart Selection</Button>
                        </div>
                        <div className="col-sm-4">
                            <Button onClick={this.setPurchase}>Set Purchase</Button>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12">
                    <div className="col-sm-4">
                        <h4>Category</h4>
                        <div className="category selected">
                            <p>{displayName}</p>
                        </div>
                        <CategoryChildren categoryMap={get(this.state.categoryMap, displayName, null)} 
                        unselectChild={this.unselectChild.bind(this)}  
                        selectNextChildren={this.selectNextChildren.bind(this)}
                        unselectHigherLevel={this.unselectHigherLevel.bind(this)} />
                    </div>
                    <div className="col-sm-4">
                        <h4>Price Range</h4>
                        { priceRanges ? this.getPriceRanges() : null }    
                    </div>
                    <div className="col-sm-4">
                        <h4>Purchase Time Range</h4>
                        { timeRanges.map(range => {
                            if(range === selectedTimeRange) {
                               return <div key={range} className="selected category">{range}</div>
                            } 
                            return <div key={range} className="category" onClick={() => this.setState({selectedTimeRange: range})}>{range}</div>
                        })}
                    </div>   
                </div>
            </div>
        )
    }


}

class UpcomingPurchases extends Component {
    state = {
        categories: [],
        topLevelCategories: [],
        topLevelCat: null
    }

    componentDidMount() {
        const categories = { select: [{"children": ["*", {"children": ["*", {"children": ["*", {"children": ["*", {"children": ["*"]}]}]}]}]}], from: ["category/descriptiveName", "root"] }
        flureeFetch("/query", categories)
            .then(res => {
                const topLevelCategories = get(res, "children")
                this.setState({ categories: res, topLevelCategories: topLevelCategories})
            })
    }

    resetPurchase(){
        this.setState({ topLevelCat: null })
    }

    render() {
        const { topLevelCategories, topLevelCat, categories } = this.state
        return (
        <>
            <div className="text-center" style={{marginTop: "50px"}}>
                <h4>Upcoming Purchases</h4>
            </div>
            <div className="row" style={{marginTop: "20px"}}>
                { 
                    topLevelCat 
                    ?
                    <CategoryMap {...this.props} categories={categories} category={topLevelCat} resetPurchase={this.resetPurchase.bind(this)}/>
                    :
                    topLevelCategories.map(category =>
                        {
                            const catName = get(category, "category/displayName")
                            const bg = require(`../../assets/img/categories/${catName}.jpg`)
                            return (
                                <div key={get(category, "category/descriptiveName")} className="col-sm-4">
                                    <div
                                        onClick={() => this.setState({topLevelCat: category})} 
                                        style={{
                                        position: "relative", height: "250px", margin: "10px", 
                                        backgroundImage: "url("+bg+")", padding: "10px",
                                        backgroundRepeat: "round", cursor:"pointer"}}>
                                        <h2 style={{color: "white", position: "absolute", bottom: "0px",
                                            WebkitTextStrokeWidth: "1px", WebkitTextStrokeColor: "black"}}>
                                        {get(category, "category/displayName")}
                                        </h2>
                                    </div>
                                </div>
                            )}
                    )
                }
            </div>
        </>
        );
    }
}

export default UpcomingPurchases;