import React, { useContext, useState } from "react";
import { Button, Modal, Box, Typography, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { flureeFetch, getToken } from "../flureeFetch";
import { requestCard } from "../utils/functions";
import UserContext from "../contexts/UserContext";

// const getModalStyle = () => {
//   const top = window.innerHeight / 2;
//   const left = window.innerWidth / 2;

//   return {
//     top: `50%`,
//     left: `${left}%`,
//     transform: `translate(-${top}%, -${left}%)`
//   };
// };

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 650,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    background: "#282560 0% 0% no-repeat padding-box",
    textAlign: "center",
    borderRadius: 14,
    boxShadow: "1px 1px 6px #000029",
    position: "relative",
    top: "33vh",
    margin: "0px auto",
    // left: '33%',
    [theme.breakpoints.down("md")]: {
      // left: 'auto'
      top: "25vh",
      width: "95%",
    },
    [theme.breakpoints.down("sm")]: {
      top: "10vh",
    },
  },
  header: {
    color: "#FFFFFF",
    marginTop: 75,
    marginBottom: 20,
  },
  subheader: {
    color: "#FFFFFF",
  },
  textBody: {
    color: "#FFFFFF",
    width: "83%",
  },
  requestButton: {
    backgroundColor: "#4096D2",
    color: "#FFFFFF",
    font: "normal normal bold 16px/21px Futura",
    borderRadius: 30,
    marginTop: 33,
    border: "3px solid #4096D2",
    padding: "15px 20px",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#4096D2",
    },
  },
  requestLabel: {},
  cancelButton: {
    marginTop: 15,
    marginBottom: 30,
  },
  cancelLabel: {
    color: "#FFFFFF",
    font: "normal normal normal 14px/19px Open Sans",
  },
  closeIconRoot: {
    display: "block",
    position: "absolute",
    top: 25,
    right: "4%",
  },
  closeIcon: {
    color: "#FFFFFF",
  },
  modalBackdrop: {
    WebkitFilter: "blur(5px)",
    msFilter: "blur(5px)",
    filter: "blur(5px)",
  },
  modalRoot: {
    outline: "none",
  },
  ".MuiButton-contained": {
    color: "#FFFFFF",
  },
}));

export default function ApplyModal({ open, onClose }) {
  const classes = useStyles();
  const { user, refreshUser } = useContext(UserContext);
  // const [modalStyle] = useState(getModalStyle);
  const applied = user["user/applied"];
  const emailAddress = user["user/email"];
  const userName = `${user["user/firstName"]} ${user["user/lastName"]}`;
  const earnings = user["user/wallet"]["wallet/earnings"];
  // const date = new Date().toDateString();
  const date = new Date();
  const dateString = date.toDateString();

  function clickHandler() {
    const userObj = {
      email: emailAddress,
      name: userName,
      earnings,
      date: dateString,
    };
    // request the card
    const authToken = getToken();
    requestCard(userObj, authToken)
      .then((res) => {
        // console.log(res);
        // debugger;
      })
      .then((res) => {
        const transactions = [
          {
            _id: Number(user._id),
            applied: true,
            appliedDate: date.valueOf(),
          },
          {
            _id: Number(user["user/wallet"]["_id"]),
            balance: "#(- (?pO) 5000)",
          },
        ];
        // transactions.push({
        //   _id: Number(user._id),
        //   applied: true
        // });
        return flureeFetch("transact", transactions, authToken).then((res) => {
          onClose();
          return refreshUser();
        });
      })
      .catch((err) => {
        // debugger;
        console.log(err);
      });
  }

  const body = (
    <Box className={classes.root}>
      <IconButton
        classes={{ root: classes.closeIconRoot, label: classes.closeIcon }}
        onClick={onClose}
      >
        <CloseIcon fontSize="large" />
      </IconButton>
      <Typography id="modal-title" variant="h1" className={classes.header}>
        5000 CA$H = $50!
        <Typography variant="h4" className={classes.subheader}>
          You've unlocked your Fabric Debit Card!
        </Typography>
      </Typography>
      <Typography id="modal-body" variant="body1" className={classes.textBody}>
        We’ll send you an email with instructions to start the quick and simple registration
        process. Once you’re registered, you’ll receive your card in the mail!
      </Typography>
      <button className={classes.requestButton} onClick={clickHandler}>
        Request Registration Link
      </button>
      <Button
        classes={{ root: classes.cancelButton, label: classes.cancelLabel }}
        onClick={onClose}
      >
        Cancel
      </Button>
    </Box>
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-body"
      classes={{ root: classes.modalRoot }}
      BackdropProps={{
        style: { backdropFilter: "blur(30px)" },
      }}
    >
      {body}
    </Modal>
  );
}
