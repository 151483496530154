import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import { Container, Grid, Link } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import EarningHistory from "../components/EarningHistory";
import FabricCard from "../components/FabricCard";
import AdsPreview from "../components/AdsPreview";
import EarningsCard from "../components/EarningsCard";
import ApplyModal from "../components/ApplyModal";
import ProfileCompleteness from "../components/ProfileCompleteness";
import BalanceOverview from "../components/BalanceOverview";
import { getWalletStats } from "../utils/functions";
import get from "lodash.get";
import UserContext from "../contexts/UserContext";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(3),
    },
    paddingBottom: theme.spacing(3),
    backgroundColor: theme.palette.primary.light,
    backgroundSize: "100% 100%",
    width: "100%",
    maxHeight: "100vh",
    height: "100%",
  },
  container: {
    backgroundColor: theme.palette.primary.light,
  },
  grid: {
    maxWidth: 1337,
    marginTop: theme.spacing(2),
    // paddingRight: theme.spacing(3)
    // maxWidth: 960
  },
  bottomGrid: {
    maxWidth: 1337,
    // [theme.breakpoints.down('lg')]: {
    //   justifyContent: 'space-between',
    //   alignItems: 'stretch'
    // },
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row-reverse",
      // alignItems: 'stretch'
    },
    // [theme.breakpoints.down('sm')]: {
    //   width: '100%',
    //   flexDirection: 'column',
    //   alignItems: 'stretch'
    // }
  },
  title: {
    color: theme.palette.text.primary,
  },
  earnings: {
    [theme.breakpoints.down("sm")]: {
      //   flexDirection: 'column',
      //   alignItems: 'stretch',
      //   margin: 0,
    },
  },
}));

function Dashboard({ user, ...rest }) {
  const classes = useStyles();
  const [earnings, setEarnings] = useState({
    toDate: user["user/wallet"]["wallet/earnings"],
    thisWeek: 0,
    thisMonth: 0,
  });
  const { blockData } = useContext(UserContext);
  const walletName = get(user, ["user/wallet", "wallet/name"]);
  const walletEarnings = get(user, ["user/wallet", "wallet/earnings"]);
  const nowDate = new Date();
  const dayAgoDate = new Date().setDate(nowDate.getDate() - 1);
  const weekAgoDate = new Date().setDate(nowDate.getDate() - 7);
  const monthAgoDate = new Date().setMonth(nowDate.getMonth() - 1);

  useEffect(() => {
    if (blockData) {
      const blockOneDate = blockData["_block/instant"];
      getWalletStats(walletName, blockOneDate, dayAgoDate, weekAgoDate, monthAgoDate).then(
        (res) => {
          // console.log({ res });
          // debugger;
          // setEarnings({
          //   toDate: walletEarnings,
          //   thisWeek: res.weekEarnings,
          //   thisMonth: res.monthEarnings,
          // });
        }
      );
    }
  }, [walletEarnings, blockData]);

  return (
    <div className={classes.root}>
      <Container maxWidth="xl" className={classes.container}>
        {/* <ApplyModal open /> */}
        <Typography variant="h1" className={classes.title}>
          Welcome to the Fabric Dashboard
        </Typography>
        <Typography variant="body1" className={classes.title}>
          Here you can view available CA$H balance, earn CA$H, and redeem your balance for cash!
        </Typography>
        <Grid container spacing={3} className={classes.grid}>
          {/* <Grid container xs={12} item> */}
          <Grid item xs={12}>
            {/* <EarningHistory blockData={blockData}></EarningHistory> */}
            <AdsPreview user={user} />
          </Grid>
        </Grid>
        <Grid
          className={classes.bottomGrid}
          container
          item
          spacing={3}
          xs={12}
          justify="space-between"
          wrap="wrap"
          alignContent="stretch"
        >
          {/* <Grid item lg={5} xl={4} xs={12}>
            <BalanceOverview></BalanceOverview>
          </Grid> */}
          <Grid
            lg={4}
            xs={12}
            item
            container
            spacing={3}
            className={classes.earnings}
            direction="row"
            wrap="wrap"
            // alignItems="center"
            alignContent="space-evenly"
            // alignContent="center"
            justify="space-between"
          >
            <Grid item lg={12} md={4} xs={12}>
              <EarningsCard earnings={earnings.thisWeek} duration="this week" />
            </Grid>
            <Grid item lg={12} md={4} xs={12}>
              <EarningsCard earnings={earnings.thisMonth} duration="this month" />
            </Grid>
            <Grid item lg={12} md={4} xs={12}>
              <EarningsCard earnings={earnings.toDate} duration="to date" />
            </Grid>
            <Grid item>
              <Typography color="textSecondary">
                Questions?{" "}
                <Link
                  align="right"
                  color="secondary"
                  href="mailto:customerservice@wearefabric.io?subject=Data Marketplace"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Contact Fabric
                </Link>
              </Typography>
            </Grid>
          </Grid>
          <Grid item lg={8} xs={12}>
            <FabricCard threshold={5000}></FabricCard>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Dashboard;
