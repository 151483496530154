import React, { useState, useEffect } from "react";
import Authenticated from "./components/Authenticated";
import AdminRouting from "./screens/Admin";
import Initiate from "./screens/Initiate";
import Unauthenticated from "./components/Unauthenticated";
import { flureeFetch, getToken, newToken } from "./flureeFetch";
import jwt from "jsonwebtoken";
import "./assets/css/demos/photo.css";

function App2() {
  const [user, setUser] = useState(null);
  const [dbs, setDbs] = useState(null);
  const [admin, setAdmin] = useState(false);
  const [blockData, setBlockData] = useState();
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");

  const beginLoading = (text = "Loading...") => {
    setLoading(true);
    setLoadingText(text);
  };

  const endLoading = () => {
    setLoading(false);
  };

  const refreshUser = () => {
    const userToken = getToken();
    debugger;
    if (userToken) {
      const localUser = jwt.decode(userToken).sub;
      const query = {
        blockData: {
          selectOne: ["_block/instant"],
          from: ["_block/number", 1],
        },
        user: {
          selectOne: ["*", { "user/plaid": ["*"] }, { "user/cashouts": ["*"] }],
          from: Number(localUser),
        },
      };
      debugger;
      return flureeFetch("multi-query", query, userToken)
        .then((res) => {
          debugger;
          if (res && res.user && res.user["user/adminAuth"]) {
            setUser(res.user);
            setAdmin(true);
          } else if (res && res.user && res.user["user/handle"]) {
            const userObj = res.user;
            if (!userObj["user/applied"]) {
              userObj["user/applied"] = false;
            }
            setUser(userObj);
            setBlockData(res.blockData);
            setAdmin(false);
          } else {
            throw new Error("There was an error authenticating you as a valid user");
          }
          setReady(true);
        })
        .catch(async (error) => {
          // if (/Authentication token/.test(error.message)) {
          //   await newToken().then(res => refreshUser());
          //   return;
          // }
          window.localStorage.removeItem("stringBean");
          debugger;
          await flureeFetch("/dbs").then((res) => {
            setDbs(res);
            setReady(true);
          });
        });
    } else {
      debugger;
      setUser(null);
      setDbs([["fabric", "mvp4"]]);
      setReady(true);
      return;
      // return flureeFetch("/dbs")
      //   .then((res) => {
      //     debugger;
      //     setUser(null);
      //     setDbs(res);
      //     setReady(true);
      //   })
      //   .catch((err) => {
      //     // newToken().then(res => {
      //     return refreshUser();
      //     // });
      //   });
    }
  };

  const getModule = () => {
    debugger;
    if (ready) {
      if (user && admin === true) {
        return (
          <AdminRouting
            beginLoading={beginLoading}
            endLoading={endLoading}
            user={user}
            refreshUser={refreshUser}
          />
        );
      } else if (user) {
        return <Authenticated user={user} blockData={blockData} refreshUser={refreshUser} />;
      } else if (dbs && dbs.length === 0) {
        return <Initiate refreshUser={refreshUser} />;
      } else {
        return <Unauthenticated refreshUser={refreshUser}></Unauthenticated>;
      }
    } else {
      return <></>;
    }
  };
  useEffect(() => {
    // getToken().then(res => {
    refreshUser();
    // .then(() => {});
    // });
  }, []);

  return <>{getModule()}</>;
}

export default App2;
