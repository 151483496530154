import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ApplyModal from "./ApplyModal";
import UserContext from "../contexts/UserContext";
import fabricCard from "../assets/img/thumbnail_virtual_card_front.png";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "10px",
    // maxHeight: 469,
    // height: '100%',
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    alignItems: "stretch",
    // maxWidth: 820,
    width: "100%",
    boxShadow: "0px 1px 12px #E3E3E3",
    boxSizing: "border-box",
    // padding: '35px 0',
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      paddingBottom: 0,
    },
  },
  info: {
    width: "55%",
    // height: '90%',
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    marginLeft: 35,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      width: "100%",
      order: 2,
    },
  },
  header: {
    marginTop: 25,
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
    },
  },
  subheader: {
    fontSize: 16,
    lineHeight: "21px",
    fontWeight: "medium",
    fontFamily: "Futura",
  },
  content: {
    // height: '70%'
    display: "inherit",
    flexDirection: "column",
    justifyContent: "flex-start",
    // '&>last-child': {
    //   paddingBottom: 0
    // }
  },
  text: {
    // height: '70%'
    // marginBottom: 20,
  },
  paragraph: {
    marginTop: 15,
    // display: 'inline-flex'
  },
  progress: {
    // height: '25%',
    marginTop: 25,
    marginBottom: 0,
  },
  progressBar: {
    height: 40,
    background: "transparent",
    border: `5px solid ${theme.palette.secondary.main}`,
    opacity: 0.5,
    marginTop: 15,
  },
  cardImage: {
    background: `transparent url(${fabricCard}) 0% 0% no-repeat padding-box`,
    opacity: 1,
    width: "30%",
    height: 379,
    backgroundSize: "contain",
    backgroundPosition: "center",
    alignSelf: "center",
    margin: "30px auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "10px auto",
      order: 3,
    },
  },
  legal: {
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      order: 1,
    },
  },
  legalText: {
    font: "normal normal normal 10px/19px Open Sans",
    textAlign: "center",
    letterSpacing: "0px",
    color: "#9D9DA3",
    width: "95%",
    marginLeft: "2.5%",
    marginBottom: 10,
    // marginTop: 50,
    [theme.breakpoints.down("xs")]: {
      width: "95%",
      marginBottom: 15,
      fontSize: 10,
    },
  },
  requestButton: {
    borderRadius: 30,
    backgroundColor: "#4096D2",
    marginTop: 15,
    width: "80%",
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  },
  requestButtonLabel: {
    color: "#FFFFFF",
    margin: theme.spacing(2),
    font: "normal normal bold 16px/21px Futura",
  },
}));
export default function FabricCard({ threshold }) {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const { user } = useContext(UserContext);
  const earnings = user["user/wallet"]["wallet/earnings"];

  const convertCash = (cash) => {
    return (cash / 100).toFixed(2);
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <Card className={classes.root}>
      <div className={classes.info}>
        <CardHeader
          title={`Earn ${threshold} CA$H ($${convertCash(
            threshold
          )}) to unlock your Fabric Debit Card`}
          titleTypographyProps={{ variant: "h1" }}
          className={classes.header}
        />
        <CardContent className={classes.content}>
          <div className={classes.text}>
            <Typography variant="body1" className={classes.paragraph}>
              Fabric is pioneering a new category of “data rewards” or cash back for consumer data.
              Consumers control their digital identity and establish value-based relationships with
              brands they love.
            </Typography>
          </div>
          <div className={classes.progress}>
            {threshold > earnings ? (
              <>
                <Typography variant="body1">
                  Earn {threshold - earnings > 0 ? threshold - earnings : 0} more CA$H to unlock
                  your Fabric Debit Card. You will be placed on our VIP waitlist to receive your
                  card. Once the Fabric App is out you can cash out your Social CA$H Tokens to USD!
                </Typography>
                <LinearProgress
                  value={(earnings / threshold) * 100}
                  variant="determinate"
                  className={classes.progressBar}
                  color="secondary"
                />
              </>
            ) : (
              <>
                <Typography variant="h4">You qualify for a Fabric Debit Card!</Typography>

                {user["user/applied"] ? (
                  <Typography className={classes.subheader}>
                    Check your email for your exclusive access link
                  </Typography>
                ) : (
                  <Button
                    className={classes.requestButton}
                    classes={{ label: classes.requestButtonLabel }}
                    onClick={handleOpen}
                    variant="contained"
                  >
                    Join our VIP Waitlist to be one of the first to receive your card
                  </Button>
                )}
              </>
            )}
          </div>
        </CardContent>
      </div>
      <div className={classes.cardImage}></div>
      <ApplyModal open={openModal} onClose={handleClose} />
    </Card>
  );
}
